import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useEffect } from 'react';

const CountryModal = (props) => {
    const CountryList = props.CountryList
    const [Search, setSearch] = useState(null)

    return (
        <Modal centered show={props.ModalStatus} onHide={props.ModalHandle} animation={false} className="LoginOptionModal modalCountries">
            <div className="title">Select Country</div>
            <div className="search">
                <input type="text" onKeyUp={e => setSearch(e.target.value.toLowerCase())} name="" id="" placeholder="Search ..." />
            </div>
            {CountryList ? (
                <ul>
                    {Object.keys(CountryList).map(e => {
                        const html = (
                            <li onClick={() => {
                                props.SelectCode(CountryList[e])
                                props.ModalHandle()
                                setSearch(null)
                            }}>
                                <img src={CountryList[e].flag} alt="" />
                                {CountryList[e].label.length > 20 ? CountryList[e].label.substr(0, 17) + '...' : CountryList[e].label}
                                {' (' + e + ')'}
                                <span className="pull-right">+{CountryList[e].code}</span>
                            </li>
                        )
                        return (
                            <>
                                {Search ? CountryList[e] ? CountryList[e].label.toLowerCase().match(Search) ? html : '' : '' : html}
                            </>
                        )
                    })}
                </ul>
            ) : ''}
        </Modal>
    );
}

export default CountryModal;