import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { apiDomain } from '../../../../../classes/Config';
import HandleServerError from '../../../../../classes/HandleServerError';

const CurrencyList = () => {
    const [price, setPrice] = useState(null);
    const history = useHistory();

    useEffect(() => {
        getTransactions();
    }, []);

    const getTransactions = () => {
        let formData = new FormData();
        let data = {
            'token': localStorage.getItem("user_loged").toString(),
            'device': "mobile: web",
        };
        Object.keys(data).map(element => {
            formData.append(element, data[element]);
        });
        axios.post(apiDomain + 'panel/getwalletamount.json', formData)
            .then(function (response) {
                setPrice(response.data.data)
            })
            .catch(function (error) {
                if (error && error.response) {
                    HandleServerError(history, error);
                }
            });
    }
    return (
        <>
            <ul className="clearfix">
                <li>
                    <div className="total">{price && price.urial ? price.urial.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, '.') : 0}</div>
                    <div className="labels">ریال سایر</div>
                </li>
                <li>
                    <div className="total">{price && price.rial ? price.rial.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, '.') : 0}</div>
                    <div className="labels">ریال شرکتی</div>
                </li>
                <li>
                    <div className="total">{price && price.yuan ? price.yuan.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, '.') : 0}</div>
                    <div className="labels">یوان</div>
                </li>
            </ul>
        </>
    );
}

export default CurrencyList;