import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { tnFormat } from '../../../../../classes/utility';

const SuccessModal = (props) => {
    const history = useHistory();
    const redirectOrders = () => {
        history.push(props.tnOrder ? '/order/' + tnFormat(props.tnOrder) : '/');
    }
    return (
        <Modal centered show={props.modalState} onHide={() => false} animation={false} className="customModalBg">
            <div className="noBorderModalContent">
                <div className="content">
                    <div className="whitetext">
                        {props.msg}
                    </div>
                </div>
                <Button type="submit" onClick={() => redirectOrders()} variant="primary" size="lg" block>خب</Button>
            </div>
        </Modal>
    );
}

export default SuccessModal;