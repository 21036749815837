import React, { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CrateOrderContext from '../Context';

const ListOrderProductsModal = (props) => {
    const data = useContext(CrateOrderContext).ListOrderProductsData;
    const editDisable = useContext(CrateOrderContext).editDisable;
    const orderProductModal = data.orderProductModal;
    const orderProductModalClose = data.orderProductModalClose;
    const orderAddProductModalShow = data.orderAddProductModalShow;
    const orderProductCollection = data.orderProductCollection;
    const setEditModalState = data.setEditModalState;
    const setProductEditRow = data.setProductEditRow;
    const deleteOrderProduct = data.deleteOrderProduct;

    return (
        <Modal centered show={orderProductModal} onHide={orderProductModalClose} animation={false} className="customModalBg">
            <div className="noBorderModalContent listLimitModalWithSearch">
                <div className="content listOrderProduct">
                    {useContext(CrateOrderContext).buttonAddPrduct ? (
                        <Button variant="success" onClick={orderAddProductModalShow} className="btn_wide">اضافه کردن کالا <span className="ti-plus icon"></span></Button>
                    ) : ''}
                    {Object.keys(orderProductCollection).length ? Object.keys(orderProductCollection).map(element => (
                        <>
                            <li key={element}>
                                <div className="detail" onClick={() => {
                                    setEditModalState(true);
                                    setProductEditRow({ data: orderProductCollection[element], key: element });
                                }}>
                                    <p>{orderProductCollection[element].title_fa}</p>
                                    <p>تعداد : {orderProductCollection[element].count}</p>
                                    <p>وزن هر واحد : {orderProductCollection[element].weight} کیلو گرم</p>
                                </div>
                                {!editDisable ? (
                                    <div className="icon">
                                        <i onClick={() => deleteOrderProduct(element)} className="ti-close close"></i>
                                    </div>
                                ) : ''}
                            </li>
                        </>
                    )) : ''}
                </div>
                <Button onClick={orderProductModalClose} variant="primary" size="lg" block>ثبت</Button>
            </div>
        </Modal>
    );
}

export default ListOrderProductsModal;