import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { apiDomain } from '../../../../../classes/Config';
import LoadingButtonModal from '../../../../common/LoadingButtonModal';
import SuccessModal from './SuccessModal';

const Charge = () => {

    const pureDataCharge = {
        'price': null,
        'payment_type': null,
        'image': null,
        'priceunit': null,
    };

    const uploadFile = useRef(null);
    const [showCurrency, setShowCurrency] = useState(false);
    const [chargeModal, setChargeModal] = useState(false);
    const [chargeData, setChargeData] = useState(pureDataCharge);
    const [Unit, setUnit] = useState('yuan');
    const [paymentTypeModal, setPaymentTypeModal] = useState(false);
    const [paymentTypesList, setPaymentTypesList] = useState(null);
    const [ModalSuccess, setModalSuccess] = useState(false);
    const [ModalSuccessMsg, setModalSuccessMsg] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getPaymentTypesList();
    }, []);

    useEffect(() => {
        getPaymentTypesList()
    }, [Unit]);

    const chargeModalClose = () => {
        setChargeModal(false);
        setChargeData(pureDataCharge);
    }

    const getPaymentTypesList = async () => {
        try {
            let formData = new FormData();
            let data = {
                'token': localStorage.getItem("user_loged").toString(),
                'device': "mobile: web",
                'unit': Unit,
                'pkg': 'express.psp.bpm.customer'
            };
            Object.keys(data).map(element => {
                formData.append(element, data[element]);
            });
            const resp = await axios.post(apiDomain + 'panel/formfields.json', formData);
            if (resp.data.status) {
                setPaymentTypesList(resp.data.payment_type)
            }
        } catch (err) {
            // console.error(err);
        }
    }

    const handleUploadFile = (event) => {
        let file = event.target.files[0];
        if (file) {
            setChargeData({ ...chargeData, image: file });
        }
    }

    const formSubmit = (e) => {
        e.preventDefault();
        
        try {

            if (!chargeData.price) {
                alert('مبلغ را وارد نمائید');
                return false;
            }

            if (!chargeData.priceunit) {
                alert('ارز را انتخاب نمائید');
                return false;
            }

            if (!chargeData.payment_type) {
                alert('نوع پرداخت را انتخاب نمائید');
                return false;
            }

            if (!chargeData.image) {
                alert('تصویر سند را انتخاب نمائید');
                return false;
            }
            setLoading(true);
            
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            let formData = new FormData();
            Object.keys(chargeData).map(element => {
                formData.append(element, chargeData[element]);
            });

            formData.append('token', localStorage.getItem("user_loged").toString());
            formData.append('device', "mobile: web");
            formData.append('pkg', 'express.psp.bpm.customer');

            axios.post(apiDomain + 'panel/orderaddpaymentcreadit.json', formData, config)
                .then(function (response) {
                    if (response.data.status) {
                        chargeModalClose();
                        setModalSuccess(true);
                        setModalSuccessMsg(response.data.msg);
                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    // console.log(error);
                });

        } catch (error) {
            // console.log(error);
        }
    }

    return (
        <>
            <SuccessModal modalState={ModalSuccess} modalClose={() => setModalSuccess(false)} message={ModalSuccessMsg} />
            <Modal centered show={paymentTypeModal} onHide={() => setPaymentTypeModal(false)} animation={false}>
                <div className="noBorderModalContent listLimitModalWithSearch">
                    <div className="content">
                        <ul className="heightAuto">
                            {paymentTypesList ? paymentTypesList.map(element => (
                                <>
                                    <li onClick={() => {
                                        setPaymentTypeModal(false);
                                        setChargeModal(true);
                                        setChargeData({
                                            ...chargeData, payment_type: {
                                                'key': element.key,
                                                'value': element.value
                                            }
                                        });
                                    }} key={element.key}>{element.value}</li>
                                </>
                            )) : ''}
                        </ul>
                    </div>
                </div>
            </Modal>

            <Modal centered show={chargeModal} onHide={() => chargeModalClose()} animation={false} className="customModalBg">
                <div className="noBorderModalContent listLimitModalWithSearch">
                    <Form onSubmit={formSubmit}>
                        <div className="content">
                            <div className="titleModal">افزایش موجودی {Unit === 'yuan' ? 'یوانی' : 'ریال سایر'} کیف پول</div>
                            <p className="descriptionModal">
                                برای افزایش موجودی کیف پول موارد ذیل را کامل کرده و ارسال کنید . پس از بررسی و تایید, مبلغ به حساب شما اضافه خواهد شد .
                            </p>
                            <Form.Group>
                                <Form.Label>مبلغ مورد نظر را در کادر ذیل وارد کنید</Form.Label>
                                <Form.Control onKeyUp={event => setChargeData({ ...chargeData, price: event.target.value })} className="text-center" placeholder={chargeData.price} type="number" />
                            </Form.Group>
                            <Form.Group controlId="transport">
                                <Form.Label>نوع پرداخت را انتخاب کنید</Form.Label>
                                <div className="form-control" onClick={() => {
                                    setPaymentTypeModal(true);
                                }}>
                                    {chargeData.payment_type ? chargeData.payment_type.value : 'انتخاب کنید'}
                                    <i className="fa fa-angle-down icon_field"></i>
                                </div>
                            </Form.Group>
                            <Button variant="success" onClick={event => uploadFile.current.click()} className="btn_wide">انتخاب تصویر سند پرداخت</Button>
                            <input type="file" hidden ref={uploadFile} onChange={event => handleUploadFile(event)} accept="image/*" />
                            {chargeData.image ? (<div className="smallNoti">تصویر پیوست شد</div>) : ''}
                        </div>
                        <Button type="submit" variant="primary" size="lg" block>
                            ارسال
                            <LoadingButtonModal show={loading} />
                        </Button>
                    </Form>
                </div>
            </Modal>

            <div className="chargeButton buttonStyle">
                <div className="cols" onClick={() => setShowCurrency(!showCurrency)}>
                    <div className="labels">افزایش موجودی</div>
                    <div className="whiteBoxFix"><i className={showCurrency ? 'fa fa-arrow-up' : 'fa fa-arrow-down'} aria-hidden="true"></i></div>
                </div>
                {showCurrency ? (
                    <>
                        <div className="cols" onClick={() => {
                            setUnit('yuan')
                            setChargeModal(true);
                            setChargeData({ ...chargeData, priceunit: 'yuan' });
                        }}>
                            <div className="labels green">یوان</div>
                            <div className="whiteBoxFix"><i className="fa fa-plus" aria-hidden="true"></i></div>
                        </div>
                        <div className="cols" onClick={() => {
                            setUnit('rial')
                            setChargeModal(true);
                            setChargeData({ ...chargeData, priceunit: 'rial' });
                        }}>
                            <div className="labels green">ریال سایر</div>
                            <div className="whiteBoxFix"><i className="fa fa-plus" aria-hidden="true"></i></div>
                        </div>
                    </>
                ) : ''}
            </div>
        </>
    );
}

export default Charge;