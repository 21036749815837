import React, { useContext } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import CrateOrderContext from '../Context';

const ServicesModal = (props) => {
    const data = useContext(CrateOrderContext).serviceModalData;
    return (
        <Modal centered show={data.show} onHide={data.handleClose} animation={false}>
            <div className="noBorderModalContent">
                <Form>
                    <div className="mb-3">
                        <Form.Check
                            custom
                            key='transport'
                            id={`custom-transport`}
                            onChange={event => data.settTransport(event.target.checked)}
                            type='checkbox'
                            label={data.TypeServiceList.transport}
                            defaultChecked={data.transport}
                        />
                    </div>
                    <div className="mb-3">
                        <Form.Check
                            custom
                            key='buy'
                            id={`custom-buy`}
                            onChange={event => data.setbuy(event.target.checked)}
                            type='checkbox'
                            label={data.TypeServiceList.buy}
                            defaultChecked={data.buy}
                        />
                    </div>
                    <div className="mb-3">
                        <Form.Check
                            custom
                            key='clearance'
                            id={`custom-clearance`}
                            onChange={event => data.setclearance(event.target.checked)}
                            type='checkbox'
                            label={data.TypeServiceList.clearance}
                            defaultChecked={data.clearance}
                        />
                    </div>
                    <Button variant="primary" size="lg" onClick={data.handleClose} block>تایید</Button>{' '}
                </Form>
            </div>
        </Modal>
    );
}

export default ServicesModal;