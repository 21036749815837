import axios from 'axios';
import React, { useRef } from 'react';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { apiDomain } from '../../../../../classes/Config';
import { numberWithCommas } from '../../../../../classes/utility';
import LoadingButtonModal from '../../../../common/LoadingButtonModal';


const PaymentRialModalComponent = (props) => {
    const invoice = props.invoice;
    const [loading, setLoading] = useState(false);
    const [Payed, setPayed] = useState(null);
    const [HyperLink, setHyperLink] = useState(null)
    const hyperElement = useRef(null)

    const payment = (value) => {
        setLoading(true);
        let formData = new FormData();
        formData.append('token', localStorage.getItem("user_loged"));
        formData.append('invoice_id', invoice.id);
        formData.append('unit', value);
        formData.append('device', "mobile: web");

        axios.post(apiDomain + 'panel/invoicepayinvoicefrombalance.json', formData)
            .then(function (response) {
                setLoading(false);
                if (!response.data.is_enough) {
                    setHyperLink(invoice.paylink);
                    props.handleModal();
                    setTimeout(() => {
                        setHyperLink(null);
                    }, 100);
                } else {
                    setPayed(response.data.msg);
                    props.getInvoices();
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }
    return (
        <>
            <Modal centered show={props.modalState} onHide={props.handleModal} animation={false} className="customModalBg">
                <div className="noBorderModalContent invoicesPaymentModal">
                    <div className="content">
                        {Payed ? (
                            <p>{Payed}</p>
                        ) : (
                            <p>
                                آیا تمایل به پرداخت استعلام ترخیص با مبلغ {invoice ? numberWithCommas(invoice.total_price) : ''} ریال را دارید ؟
                            </p>
                        )}
                    </div>
                    <div className="buttonBlock">
                        {/* {HyperLink ? (
                        ) : ''} */}
                        <a ref={hyperElement} target="_blank" href={invoice && invoice.paylink ? invoice.paylink : ''} style={{ 'display': 'block', 'width': '100%' }}>
                            <Button variant="primary" onClick={() => Payed ? props.handleModal() : payment()} size="lg" block>{Payed ? 'خب' : 'پرداخت'}</Button>
                        </a>
                        {/* <LoadingButtonModal show={loading} /> */}
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default PaymentRialModalComponent;