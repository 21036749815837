export const LoginReducer = (state = false, action) => {
  switch (action.type) {
    case 'login':
      localStorage.setItem('user_loged', action.payload);
      return true;
    case 'logout':
      return false;
    default:
      return state;
  }
};
