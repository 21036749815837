import axios from 'axios';
import React, { useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { apiDomain } from '../../../../classes/Config';
import { tnFormat } from '../../../../classes/utility';
import LoadingButtonModal from '../../../common/LoadingButtonModal';
import NumberFormat from 'react-number-format';

const OptionModal = (props) => {

    const [Loading, setLoading] = useState(false);
    const history = useHistory();

    const checkTn = (e) => {
        e.preventDefault()
        const tn = e.target.elements.tnField.value;

        if (!tn) {
            return false
        }

        setLoading(true)
        let formData = new FormData();
        let data = {
            'tn': tn,
            'is_search': true,
            'device': "mobile: web",
            'pkg': 'express.psp.bpm.customer',
        };

        Object.keys(data).map(element => {
            formData.append(element, data[element]);
        });

        axios.post(apiDomain + 'panel/orderdetails.json', formData)
            .then(function (response) {
                setLoading(false)
                if (response.data.status) {
                    history.push('/search/' + tnFormat(tn))
                } else {
                    alert(response.data.msg);
                }
            })
            .catch(function (error) {
            });
    }

    return (
        <Modal centered show={props.ModalOption} onHide={props.ModalHide} animation={false} className="customModalBg LoginOptionModal">
            <Form onSubmit={checkTn}>
                <div className="noBorderModalContent">
                    <div className="content">
                        <div className="text">
                            <p>شماره سفارش خود را وارد کنید</p>
                            <small>شماره سفارش را به صورت کامل وارد نمایید</small>
                        </div>
                        {/* <Form.Control maxLength="11" key='codesms' type="text" ref={tnElement} placeholder="شماره سفارش" className="field" /> */}
                        <NumberFormat name="tnField" className="field form-control" placeholder="شماره سفارش" format="###-###-###" />
                    </div>
                    <Button type="submit" variant="primary" size="lg" block>
                        جستجو
                    <LoadingButtonModal show={Loading} />
                    </Button>
                </div>
            </Form>
        </Modal>
    );
}

export default OptionModal;