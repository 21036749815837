import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { apiDomain } from '../../../../classes/Config';
import PageLayout from '../../../../hoc/PageLayout';
import PageHeader from '../../../common/PageHeader';
import ReloadPage from '../../../common/ReloadPage';

const Tracking = (props) => {
    const [tracks, setTracks] = useState(null);
    const ConnectionReducer = useSelector(state => state.ConnectionReducer);

    useEffect(() => {
        getOrder();
    }, []);


    const formUrlEncoded = x =>
        Object.keys(x).reduce((p, c) => p + `&${c}=${encodeURIComponent(x[c])}`, '')

    const getOrder = async () => {
        axios.post(apiDomain + 'public/track.json', formUrlEncoded({
            token: localStorage.getItem("user_loged"),
            tn: props.match.params.tn.match(/\d/g).join(""),
            device: "mobile: web",
        }))
            .then(function (response) {
                setTracks(response.data);
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const lastTrack = tracks ? tracks.reverse()[0] : null;
    return (
        <>
            <PageHeader prevLink={localStorage.getItem("user_loged") ? '/order/' + props.match.params.tn : '/login'} titlePage={props.match.params.tn + ' - وضعیت سفارش'} />
            <div className="clearfix"></div>
            {ConnectionReducer ? (
                <>
                    {tracks ? (
                        <div className="page_tracking">
                            <div className="head">
                                <div className="title">آخرین وضعیت سفارش:</div>
                                <div className="status">{lastTrack.status}</div>
                                {tracks[0].time ? (
                                    <div className="date">{lastTrack.time}</div>
                                ) : ''}
                            </div>
                            {tracks[0].time ? (
                                <div className="history">
                                    <div className="title_history">تاریخچه</div>
                                    <ul>
                                        {tracks.map((element, key) => {
                                            const titleList = element.status.split('-');
                                            return (
                                                <li key={key}>
                                                    <i className={!key ? 'icon green fa fa-cog' : 'icon fa fa-check'}></i>
                                                    <div className="title">
                                                        {titleList ? titleList.map((element, key) => (
                                                            <>
                                                                {key != 0 ? (
                                                                    <span>{key > 1 ? ' - ' : ''}{element}</span>
                                                                ) : ''}
                                                            </>
                                                        )) : ''}
                                                    </div>
                                                    <div className="detail">
                                                        <span><i className="fa fa-cog"></i> {element.status.split('-')[0]}</span>
                                                        <span><i className="fa fa-clock-o"></i> {element.time}</span>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            ) : ''}
                        </div>
                    ) : ''}
                </>
            ) : <ReloadPage />}
        </>
    );
}

export default PageLayout(withRouter(Tracking));