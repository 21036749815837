import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { seo } from '../../../../classes/Seo';
import PageLayout from '../../../../hoc/PageLayout';
import PageHeader from '../../../common/PageHeader';
import { apiDomain } from '../../../../classes/Config';
import EditModal from './parts/EditModal';
import SuccessModal from './parts/SuccessModal';
import CrateOrderContext from './Context';
import { useSelector } from 'react-redux';
import HandleServerError from '../../../../classes/HandleServerError';
import ReloadPage from '../../../common/ReloadPage';
import FirstDetail from './parts/FirstDetail';
import ServicesModal from './parts/ServicesModal';
import CountryModal from './parts/CountryModal';
import OrderProductModal from './parts/OrderProductModal';
import ListOrderProductsModal from './parts/ListOrderProductsModal';

const CreateNewOrder = () => {

    const uploadFile = useRef(null);
    const ProformaInvoiceElement = useRef(null);
    const PackingListElement = useRef(null);
    const history = useHistory();
    const ConnectionReducer = useSelector(state => state.ConnectionReducer);
    const orderProductData = {
        'title_en': null,
        'title_fa': null,
        'proforma_invoice': null,
        'packing_list': null,
        'count': null,
        'weight': null,
        'length': null,
        'width': null,
        'height': null,
        'images': {},
    };

    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }

    const [show, setShow] = useState(false);
    const [showCountryModalShow, setCountryModalShow] = useState(false);
    const [destinationCountryModalShow, setDestinationCountryModalShow] = useState(false);
    const [orderProductModal, setOrderProductModal] = useState(false);
    const [orderAddProductModal, setOrderAddProductModal] = useState(false);
    const [transport, settTransport] = useState(true);
    const [buy, setbuy] = useState(false);
    const [clearance, setclearance] = useState(false);
    const [country, setCountry] = useState(null);
    const [countryModel, setCountryModel] = useState(null);
    const [startingCity, setStartingCity] = useState(null);
    const [destinationCity, setDestinationCity] = useState(null);
    const [orderProduct, setOrderProduct] = useState(orderProductData);
    const [orderProductCollection, setOrderProductCollection] = useState({});
    const [editModalState, setEditModalState] = useState(false);
    const [ModalSuccess, setModalSuccess] = useState(false);
    const [tnAfterSuccess, settnAfterSuccess] = useState(null);
    const [Connection, setConnection] = useState(true);
    const [LoadingCreate, setLoadingCreate] = useState(false);
    const [productEditRow, setProductEditRow] = useState({
        'key': null,
        'data': {}
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCountryModalClose = () => {
        setCountryModalShow(false);
        setCountry(countryModel);
    }
    const handleCountryModalShow = () => {
        setCountryModalShow(true)
        setCountry(countryModel);
    };

    const handleDestiCountryModalClose = () => setDestinationCountryModalShow(false);
    const handleDestiCountryModalShow = () => {
        setDestinationCountryModalShow(true);
        setCountry(countryModel);
    }

    const orderProductModalClose = () => {
        setOrderProductModal(false);
    }
    const orderProductModalShow = () => setOrderProductModal(true);

    const orderAddProductModalClose = () => setOrderAddProductModal(false);
    const orderAddProductModalShow = () => {
        setOrderAddProductModal(true);
        setOrderProductModal(false);
    };


    const formUrlEncoded = x =>
        Object.keys(x).reduce((p, c) => p + `&${c}=${encodeURIComponent(x[c])}`, '')


    const TypeServiceList = {
        'transport': 'حمل بین المللی',
        'buy': 'خرید کالا',
        'clearance': 'ترخیص'
    }

    const TypeServiceListActive = {
        'transport': transport,
        'buy': buy,
        'clearance': clearance
    };

    const printTypeServiceListActive = () => {
        let text = '';
        Object.keys(TypeServiceListActive).map(element => {
            if (TypeServiceListActive[element]) {
                text = text + ' - ' + TypeServiceList[element];
            }
        })
        const dots = text.length > 26 ? ' ...' : '';
        return text.substr(3, 26) + dots;
    }

    useEffect(() => {
        getCountry();
        handleStartingCity("CN", "CHINA")
        handleDestinationCity("IR", "IRAN, ISLAMIC REPUBLIC OF")
        seo({
            title: 'ثبت سفارش جدید',
            backurl: 'http://localhost:3000/commingsoon',
        });
    }, []);

    const getCountry = () => {
        axios.post(apiDomain + 'panel/citieslist.json', formUrlEncoded({
            token: localStorage.getItem("user_loged"),
            device: "mobile: web",
        }))
            .then(function (response) {
                if (response.data) {
                    setCountry(response.data.data);
                    setCountryModel(response.data.data);
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const handleStartingCity = (key, value) => {
        if (key) {
            setStartingCity({
                'key': key,
                'value': value,
            });
            setCountryModalShow(false);
        }
    }

    const handleDestinationCity = (key, value) => {
        if (key) {
            setDestinationCity({
                'key': key,
                'value': value,
            });
            setDestinationCountryModalShow(false);
        }
    }

    const uploadMultiFile = (event) => {
        let file = event.target.files[0];
        let lastModified = file.lastModified;
        let size = file.size;
        let images = orderProduct.images;
        if (file) {
            setOrderProduct({ ...orderProduct, images: { ...orderProduct.images, [lastModified + size]: file } });
        }
    }

    const ProformaInvoiceUpload = (event) => {
        let file = event.target.files[0];
        if (file) {
            setOrderProduct({ ...orderProduct, proforma_invoice: file })
        }
    }

    const PackingListUpload = (event) => {
        let file = event.target.files[0];
        if (file) {
            setOrderProduct({ ...orderProduct, packing_list: file })
        }
    }

    const uploadDeleteFile = (key) => {
        let imagesData = {};
        Object.keys(orderProduct.images).map(element => {
            if (element !== key) {
                imagesData[element] = orderProduct.images[element];
            }
        });
        setOrderProduct({ ...orderProduct, images: imagesData });
    }

    const validateOrderProductFields = (data = null) => {
        if(!data){
            return false;
        }

        let error = true;
        if (!data.title_fa) {
            alert('نام را وارد نمائید');
            error = false;
            return false;
        }
        if (!data.title_en) {
            alert('نام انگلیسی را وارد نمائید');
            error = false;
            return false;
        }
        if (!data.count) {
            alert('تعداد را وارد نمائید');
            error = false;
            return false;
        }
        if (!data.weight) {
            alert('وزن را وارد نمائید');
            error = false;
            return false;
        }
        if (data.length || data.width || data.height) {
            if (!data.length) {
                alert('طول را وارد نمائید');
                error = false;
                return false;
            }
            if (!data.width) {
                alert('عرض را وارد نمائید');
                error = false;
                return false;
            }
            if (!data.height) {
                alert('ارتفاع را وارد نمائید');
                error = false
                return false;;
            }
        }
        return error;
    }

    const orderProductAdded = (e) => {
        e.preventDefault();

        if (validateOrderProductFields(orderProduct)) {
            const key = Object.keys(orderProductCollection).length + 1;
            setOrderProductCollection({ ...orderProductCollection, [(Math.floor(Math.random() * 10000) + 101) + key]: orderProduct })
            setOrderProduct(orderProductData);
            setOrderAddProductModal(false);
            setOrderProductModal(true);
        }
    }

    const deleteOrderProduct = (keyOrderProduct) => {
        let orderProductsData = {};
        Object.keys(orderProductCollection).map(element => {
            if (element !== keyOrderProduct) {
                orderProductsData[element] = orderProductCollection[element];
            }
        });
        setOrderProductCollection(orderProductsData);
    }

    const handleForm = (e) => {
        e.preventDefault();
        if (!(transport || buy || clearance)) {
            alert('نوع پرداخت را وارد نمائید');
            return false;
        }
        if (!startingCity) {
            alert('کشور مبدا را وارد نمائید');
            return false;
        }
        if (!destinationCity) {
            alert('کشور مقصد را وارد نمائید');
            return false;
        }
        if (!Object.keys(orderProductCollection).length) {
            toast.error('هیچ کالایی برای سفارش ثبت نکرده اید');
            return false;
        }

        let service = '';
        Object.keys(TypeServiceListActive).map(element => {
            if (TypeServiceListActive[element]) {
                service = service + ',' + element;
            }
        });
        service = service.substr(1, 1000);
        setLoadingCreate(true);

        axios.post(apiDomain + 'panel/orderaddstep1.json', formUrlEncoded({
            services: service,
            token: localStorage.getItem("user_loged"),
            sender_city: startingCity.key,
            receiver_city: destinationCity.key,
            device: 'mobile: web',
            pkg: 'express.psp.bpm.customer',
        }))
            .then(function (response) {
                setLoadingCreate(false);
                if (response.data.status) {
                    HandleQueryApiOrderProduct(response.data.order_id);
                    setModalSuccess(true);
                    settnAfterSuccess(response.data.order_tn);
                } else {
                    toast.error(response.data.msg);
                }
            })
            .catch(function (error) {
                setLoadingCreate(false);
                if (error && error.response) {
                    HandleServerError(history, error);
                } else {
                    setConnection(false);
                }
            });
    }

    const uploadImageOrderProduct = (images, opID) => {
        Object.keys(images).map(element => {
            let formData = new FormData();
            let data = {
                'token': localStorage.getItem("user_loged"),
                'op_id': opID,
                'photo': images[element],
                'device': 'mobile: web',
                'pkg': 'express.psp.bpm.customer',
            };

            Object.keys(data).map(element => {
                formData.append(element, data[element]);
            });

            axios.post(apiDomain + 'panel/orderproductsaddimage.json', formData, config)
                .then(function (response) {
                    // console.log(response);
                }).catch(function (error) { });

        });
    }

    const HandleQueryApiOrderProduct = (orderID) => {
        Object.keys(orderProductCollection).map(element => {
            let formData = new FormData();
            let data = {
                'order_id': orderID,
                'token': localStorage.getItem("user_loged"),
                'name': orderProductCollection[element].title_fa,
                'name_en': orderProductCollection[element].title_en,
                'quantity': orderProductCollection[element].count,
                'oneweight': orderProductCollection[element].weight,
                'attach_pi': orderProductCollection[element].proforma_invoice,
                'attach_pl': orderProductCollection[element].packing_list,
                'length': orderProductCollection[element].length,
                'width': orderProductCollection[element].width,
                'height': orderProductCollection[element].height,
                'device': 'mobile: web',
                'pkg': 'express.psp.bpm.customer'
            };

            Object.keys(data).map(element => {
                formData.append(element, data[element]);
            });

            axios.post(apiDomain + 'panel/orderaddproducts.json', formData, config)
                .then(function (response) {
                    if (response.data.status) {
                        let images = orderProductCollection[element].images;
                        let opID = response.data.op_id;
                        if (Object.keys(images).length) {
                            uploadImageOrderProduct(images, opID);
                        }
                        setOrderProductCollection({});
                        settTransport(true);
                        setbuy(false);
                        setclearance(false);
                        setStartingCity(null);
                        setDestinationCity(null);
                    }
                })
                .catch(function (error) {
                    // console.log(error);
                });
        });
    }

    const searchCountry = (keyword) => {
        if (keyword) {
            let keywords = keyword.toLowerCase();
            let formData = new FormData();
            let data = {
                'token': localStorage.getItem("user_loged"),
                'query': keywords,
                'device': 'mobile: web',
                'pkg': 'express.psp.bpm.customer',
            };

            Object.keys(data).map(element => {
                formData.append(element, data[element]);
            });

            axios.post(apiDomain + 'panel/citieslist.json', formData)
                .then(function (response) {
                    setCountry(response.data.data);
                }).catch(function (error) { });

        } else {
            setCountry(countryModel);
        }
    }

    const HandleEditModalClose = () => {
        setEditModalState(false);
        setProductEditRow({
            'key': null,
            'data': {}
        });
    }

    const handleEditProductsFunc = (product, key) => {
        setOrderProductCollection({ ...orderProductCollection, [key]: product });
        HandleEditModalClose();
    }

    const FirstDetailData = {
        'handleForm': handleForm,
        'handleShow': handleShow,
        'transport': transport,
        'buy': buy,
        'clearance': clearance,
        'printTypeServiceListActive': printTypeServiceListActive,
        'handleCountryModalShow': handleCountryModalShow,
        'startingCity': startingCity,
        'handleDestiCountryModalShow': handleDestiCountryModalShow,
        'destinationCity': destinationCity,
        'orderProductModalShow': orderProductModalShow,
        'orderProductCollection': orderProductCollection,
    };

    const serviceModalData = {
        'show': show,
        'handleClose': handleClose,
        'settTransport': settTransport,
        'TypeServiceList': TypeServiceList,
        'setbuy': setbuy,
        'setclearance': setclearance,
        'transport': transport,
        'buy': buy,
        'clearance': clearance,
    }

    const ListOrderProductsData = {
        'orderProductModal': orderProductModal,
        'orderProductModalClose': orderProductModalClose,
        'orderAddProductModalShow': orderAddProductModalShow,
        'orderProductCollection': orderProductCollection,
        'setEditModalState': setEditModalState,
        'setProductEditRow': setProductEditRow,
        'deleteOrderProduct': deleteOrderProduct,
    }

    const OrderProductModalData = {
        'orderAddProductModal': orderAddProductModal,
        'orderAddProductModalClose': orderAddProductModalClose,
        'orderProductAdded': orderProductAdded,
        'setOrderProduct': setOrderProduct,
        'orderProduct': orderProduct,
        'ProformaInvoiceElement': ProformaInvoiceElement,
        'PackingListElement': PackingListElement,
        'uploadFile': uploadFile,
        'uploadDeleteFile': uploadDeleteFile,
        'ProformaInvoiceUpload': (event) => ProformaInvoiceUpload(event),
        'PackingListUpload': (event) => PackingListUpload(event),
        'uploadMultiFile': (event) => uploadMultiFile(event),
    };
    return (
        <>
            {ConnectionReducer && Connection ? (
                <>
                    <PageHeader prevLink="/" titlePage="ثبت سفارش جدید" />
                    <CrateOrderContext.Provider value={{
                        EditModalState: editModalState,
                        handleEditProducts: handleEditProductsFunc,
                        EditModalClose: HandleEditModalClose,
                        productEditRowData: productEditRow,
                        FirstDetail: FirstDetailData,
                        serviceModalData: serviceModalData,
                        ListOrderProductsData: ListOrderProductsData,
                        OrderProductModalData: OrderProductModalData,
                        editDisable: false,
                        buttonAddPrduct: true,
                        validateOrderProductFieldsCo: validateOrderProductFields
                    }}>
                        <EditModal data={productEditRow} isEdit={false} />
                        <SuccessModal modalState={ModalSuccess} tnOrder={tnAfterSuccess} modalClose={() => setModalSuccess(false)} msg="سفارش شما با موفقیت ثبت شد و در صف بررسی قرار گرفت" />
                        <ServicesModal />
                        <CountryModal CountryModalData={{
                            'showCountryModalShow': showCountryModalShow,
                            'handleCountryModalClose': handleCountryModalClose,
                            'searchCountry': searchCountry,
                            'country': country,
                            'handleStartingCity': handleStartingCity,
                        }} />
                        <CountryModal CountryModalData={{
                            'showCountryModalShow': destinationCountryModalShow,
                            'handleCountryModalClose': handleDestiCountryModalClose,
                            'searchCountry': searchCountry,
                            'country': country,
                            'handleStartingCity': handleDestinationCity,
                        }} />
                        <ListOrderProductsModal />
                        <OrderProductModal />
                        <FirstDetail buttonCaption="ثبت سفارش" loading={LoadingCreate} />
                    </CrateOrderContext.Provider>
                </>
            ) : <ReloadPage />}
        </>
    );
}

export default PageLayout(CreateNewOrder);