import React, { useContext } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import CrateOrderContext from '../Context';
import { numericFilter } from './../../../../../classes/utility';

const OrderProductModal = (props) => {
    const data = useContext(CrateOrderContext).OrderProductModalData;
    const orderAddProductModal = data.orderAddProductModal;
    const orderAddProductModalClose = data.orderAddProductModalClose;
    const orderProductAdded = data.orderProductAdded;
    const setOrderProduct = data.setOrderProduct;
    const orderProduct = data.orderProduct;
    const ProformaInvoiceElement = data.ProformaInvoiceElement;
    const PackingListElement = data.PackingListElement;
    const uploadFile = data.uploadFile;
    const uploadDeleteFile = data.uploadDeleteFile;
    const ProformaInvoiceUpload = data.ProformaInvoiceUpload;
    const PackingListUpload = data.PackingListUpload;
    const uploadMultiFile = data.uploadMultiFile;

    return (
        <Modal centered show={orderAddProductModal} onHide={orderAddProductModalClose} animation={false} className="customModalBg">
            <div className="noBorderModalContent listLimitModalWithSearch">
                <Form onSubmit={orderProductAdded}>
                    <div className="content">
                        <Form.Group>
                            <Form.Label>عنوان کالا</Form.Label>
                            <Form.Control className="text-center" onChange={event => setOrderProduct({ ...orderProduct, title_fa: event.target.value })} placeholder='نام فارسی کالا' type="text" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control className="text-center" onChange={event => setOrderProduct({ ...orderProduct, title_en: event.target.value })} placeholder='نام انگلیسی کالا' type="text" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>تعداد کارتن</Form.Label>
                            <Form.Control className="text-center" onChange={event => {
                                setOrderProduct({ ...orderProduct, count: event.target.value })
                                numericFilter(event)
                            }} type="number" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>وزن هر واحد (کیلو گرم)</Form.Label>
                            <Form.Control className="text-center" onChange={event => {
                                setOrderProduct({ ...orderProduct, weight: event.target.value })
                                numericFilter(event)
                            }} type="number" />
                        </Form.Group>
                        <Row className="three_cloumn">
                            <Col className="paddleft">
                                <Form.Group>
                                    <Form.Label>طول (cm)</Form.Label>
                                    <Form.Control className="text-center" onChange={event => setOrderProduct({ ...orderProduct, length: event.target.value })} type="number" />
                                </Form.Group>
                            </Col>
                            <Col className="paddleft">
                                <Form.Group>
                                    <Form.Label>عرض (cm)</Form.Label>
                                    <Form.Control className="text-center" onChange={event => setOrderProduct({ ...orderProduct, width: event.target.value })} type="number" />
                                </Form.Group>
                            </Col>
                            <Col className="paddright">
                                <Form.Group>
                                    <Form.Label>ارتفاع (cm)</Form.Label>
                                    <Form.Control className="text-center" onChange={event => setOrderProduct({ ...orderProduct, height: event.target.value })} type="number" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="paddleft">
                                <Form.Group>
                                    <Form.Label className="text-left">Proforma Invoice</Form.Label>
                                    <Button variant="secondary" onClick={event => ProformaInvoiceElement.current.click()} className="btn_wide">
                                        انتخاب کنید
                                                {orderProduct['proforma_invoice'] ? (<i onClick={() => setOrderProduct({ ...orderProduct, proforma_invoice: null })} className="ti-close close"></i>) : ''}
                                    </Button>
                                    <input type="file" accept="image/*" hidden ref={ProformaInvoiceElement} onChange={event => ProformaInvoiceUpload(event)} />
                                </Form.Group>
                            </Col>
                            <Col className="paddright">
                                <Form.Group>
                                    <Form.Label className="text-left">Packing List</Form.Label>
                                    <Button variant="secondary" onClick={event => PackingListElement.current.click()} className="btn_wide">
                                        انتخاب کنید
                                                {orderProduct['packing_list'] ? (<i onClick={() => setOrderProduct({ ...orderProduct, packing_list: null })} className="ti-close close"></i>) : ''}
                                    </Button>
                                    <input type="file" accept="image/*" hidden ref={PackingListElement} onChange={event => PackingListUpload(event)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group>
                            <Button variant="success" onClick={event => uploadFile.current.click()} className="btn_wide">اضافه کردن تصویر کالا <span className="ti-plus icon"></span></Button>
                            <input type="file" hidden ref={uploadFile} accept="image/*" onChange={event => uploadMultiFile(event)} />
                            <div className="listImageUpload">
                                <ul className="clearfix">
                                    {Object.keys(orderProduct.images).length ? Object.keys(orderProduct.images).map(element => (
                                        <>
                                            <li key={'image' + element} onClick={() => uploadDeleteFile(element)}>
                                                <i className="ti-close close"></i>
                                                <img src={URL.createObjectURL(orderProduct.images[element])} />
                                            </li>
                                        </>
                                    )) : ''}
                                </ul>
                            </div>
                        </Form.Group>
                    </div>
                    <Button type="submit" variant="primary" size="lg" block>ثبت</Button>
                </Form>
            </div>
        </Modal>
    );
}

export default OrderProductModal;