import axios from 'axios';
import React, { Component, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { apiDomain } from '../../../classes/Config';
import Loading from '../../common/Loading';
import LoadingButtonModal from '../../common/LoadingButtonModal';
import PageHeader from '../../common/PageHeader';
import CountryModal from './../orderComponent/create/parts/CountryModal';
import DialogModal from './parts/DialogModal';

class ShipingCost extends Component {
    constructor(props) {
        super(props)
        this.state = {
            weight: null,
            fromCity: null,
            toCity: null,
            loading: false,
            showCountryModalShow: false,
            country: null,
            destinationCountryModalShow: false,
            dialogModalSubmited: false,
            connect: false
        }
    }

    componentDidMount() {
        this.getCountry()
    }

    getCountry = () => {
        const self = this
        const formUrlEncoded = x => Object.keys(x).reduce((p, c) => p + `&${c}=${encodeURIComponent(x[c])}`, '')
        axios.post(apiDomain + 'panel/citieslist.json', formUrlEncoded({
            token: localStorage.getItem("user_loged"),
            device: "mobile: web",
        }))
            .then(function (response) {
                if (response.data) {
                    self.setState({ country: response.data.data });
                    self.handleStartingCity("CN", "CHINA")
                    self.handleDestinationCity("IR", "IRAN, ISLAMIC REPUBLIC OF")
                    self.setState({ connect: true })
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    searchCountry = (keyword) => {
        if (keyword) {
            const self = this
            let keywords = keyword.toLowerCase();
            let formData = new FormData();
            let data = {
                'token': localStorage.getItem("user_loged"),
                'query': keywords,
                'device': 'mobile: web',
                'pkg': 'express.psp.bpm.customer',
            };

            Object.keys(data).map(element => {
                formData.append(element, data[element]);
            });

            axios.post(apiDomain + 'panel/citieslist.json', formData)
                .then(function (response) {
                    self.setState({ country: response.data.data });
                }).catch(function (error) { });

        } else {
            // this.setState({ country: null });
        }
    }

    handleStartingCity = (key, value) => {
        if (key) {
            this.setState({
                fromCity: {
                    'key': key,
                    'value': value,
                }
            });
            this.handleCountryModalClose()
        }
    }

    handleDestinationCity = (key, value) => {
        if (key) {
            this.setState({
                toCity: {
                    'key': key,
                    'value': value,
                }
            });
            this.handleDestinationModalClose()
        }
    }

    handleForm = (e) => {
        e.preventDefault()
        this.setState({loading : true})
        const self = this
        let formData = new FormData();
        let data = {
            'token': localStorage.getItem("user_loged"),
            'origin': this.state.fromCity.key,
            'destination': this.state.toCity.key,
            'weight': this.state.weight,
            'device': 'mobile: web',
            'pkg': 'express.psp.bpm.customer',
        };

        Object.keys(data).map(element => {
            formData.append(element, data[element]);
        });

        axios.post(apiDomain + 'public/estimateordercost.json', formData)
            .then(function (response) {
                let status = response.data && response.data.cost
                if (status) {
                    self.setState({
                        dialogModalSubmited: {
                            msg: response.data.msg,
                            buttons: [
                                {
                                    'title': 'خب',
                                    'method': () => self.setState({ dialogModalSubmited: null })
                                }
                            ]
                        }
                    })
                } else {
                    self.setState({
                        dialogModalSubmited: {
                            msg: response.data.msg,
                            buttons: [
                                {
                                    'title': 'تماس با پشتیبانی',
                                    'method': () => window.open('tel:02142281')
                                },
                                {
                                    'title': 'پیام به پشتیبانی',
                                    'method': () => self.props.history.push('/chat/پشتیبانی')
                                }
                            ]
                        }
                    })
                }
                self.setState({loading : false})

            }).catch(function (error) { });

    }

    handleCountryModalClose = () => {
        this.setState({ showCountryModalShow: false })
    }

    handleDestinationModalClose = () => {
        this.setState({ destinationCountryModalShow: false })
    }

    render() {
        const { fromCity, toCity, showCountryModalShow, country, destinationCountryModalShow, dialogModalSubmited, connect } = this.state
        const CountryModalDataFrom = {
            'showCountryModalShow': showCountryModalShow,
            'handleCountryModalClose': this.handleCountryModalClose,
            'searchCountry': this.searchCountry,
            'country': country,
            'handleStartingCity': this.handleStartingCity,
        }
        const CountryModalDataTo = {
            'showCountryModalShow': destinationCountryModalShow,
            'handleCountryModalClose': this.handleDestinationModalClose,
            'searchCountry': this.searchCountry,
            'country': country,
            'handleStartingCity': this.handleDestinationCity,
        }
        return (
            <>
                <DialogModal closeModal={() => this.setState({ dialogModalSubmited: null })} modalState={dialogModalSubmited ? true : false} dialogModalDataSubmited={dialogModalSubmited} />
                <PageHeader prevLink={'/'} titlePage="استعلام قیمت حمل" />
                <CountryModal CountryModalData={CountryModalDataFrom} />
                <CountryModal CountryModalData={CountryModalDataTo} />
                {connect ? (
                    <div className="page_style form_page">
                        <Form onSubmit={this.handleForm}>
                            <div className="content">
                                <Row>
                                    <Col className="paddleft">
                                        <Form.Group controlId="Country">
                                            <Form.Label>کشور مبدا</Form.Label>
                                            <Form.Control type="text" onClick={() => this.setState({ showCountryModalShow: true })} readOnly placeholder={fromCity ? fromCity.value : 'انتخاب کنید'} />
                                        </Form.Group>
                                    </Col>
                                    <Col className="paddright">
                                        <Form.Group controlId="DestiCountry">
                                            <Form.Label>کشور مقصد</Form.Label>
                                            <Form.Control type="text" onClick={() => this.setState({ destinationCountryModalShow: true })} readOnly placeholder={toCity ? toCity.value : 'انتخاب کنید'} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group controlId="transport">
                                    <Form.Label>وزن به کیلوگرم</Form.Label>
                                    <Form.Control className="text-center" onChange={e => this.setState({ weight: e.target.value })} type="number" />
                                </Form.Group>
                            </div>
                            <div className="relative">
                                <Button type="submit" variant="primary" size="lg" block>استعلام</Button>
                                <LoadingButtonModal show={this.state.loading} />
                            </div>
                        </Form>
                    </div>
                ) : <Loading />}
            </>
        );
    }
}

export default withRouter(ShipingCost);