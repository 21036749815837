import React, { useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import context from 'react-bootstrap/esm/AccordionContext';
import LoadingButtonModal from '../../../../common/LoadingButtonModal';
import CrateOrderContext from '../Context';

const FirstDetail = (props) => {
    const data = useContext(CrateOrderContext).FirstDetail;
    const editDisable = useContext(CrateOrderContext).editDisable;

    return (
        <div className="page_style form_page">
            <Form onSubmit={data.handleForm}>
                <div className="content">
                    <Form.Group controlId="transport">
                        <Form.Label>نوع خدمت را انتخاب کنید</Form.Label>
                        <div onClick={editDisable ? null : data.handleShow} className={editDisable ? "form-control disabled" : "form-control"}>
                            {!(data.transport || data.buy || data.clearance) ? 'انتخاب کنید' : data.printTypeServiceListActive()}
                            <i className="fa fa-angle-down icon_field"></i>
                        </div>
                    </Form.Group>
                    <Row>
                        <Col className="paddleft">
                            <Form.Group controlId="Country">
                                <Form.Label>کشور مبدا</Form.Label>
                                <Form.Control onClick={data.handleCountryModalShow} readOnly placeholder={data.startingCity ? data.startingCity.value : 'انتخاب کنید'} type="text" disabled={editDisable} />
                            </Form.Group>
                        </Col>
                        <Col className="paddright">
                            <Form.Group controlId="DestiCountry">
                                <Form.Label>کشور مقصد</Form.Label>
                                <Form.Control onClick={data.handleDestiCountryModalShow} readOnly placeholder={data.destinationCity ? data.destinationCity.value : 'انتخاب کنید'} type="text" disabled={editDisable} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button variant="success" onClick={data.orderProductModalShow} className="btn_wide">اضافه کردن کالا <span className="ti-plus icon"></span></Button>
                    <div className="NumberAddedOrderProduct">
                        {Object.keys(data.orderProductCollection).length ? Object.keys(data.orderProductCollection).length + ' کالا اضافه شده است' : ''}
                    </div>
                </div>
                <div className="relative">
                    <Button type="submit" variant="primary" size="lg" block>{props.buttonCaption}</Button>
                    <LoadingButtonModal show={props && props.loading ? props.loading : false} />
                </div>
            </Form>
        </div>
    );
}

export default FirstDetail;