import React from 'react';

const PageLayout = (RootComponent) => {
    return props => (
        <>
            <RootComponent/>
        </>
    );
}

export default PageLayout;