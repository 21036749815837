import React, { useEffect, useState } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import PageLayout from '../../../../hoc/PageLayout';
import { seo } from '../../../../classes/Seo';
import Detail from './parts/Detail';
import Buttons from './parts/Buttons';
import PageHeader from '../../../common/PageHeader';
import axios from 'axios';
import { apiDomain } from '../../../../classes/Config';
import Loading from '../../../common/Loading';
import HtmlParser from 'react-html-parser';
import { Button, Form, Modal } from 'react-bootstrap';
import LoadingButtonModal from '../../../common/LoadingButtonModal';
import { toast } from 'react-toastify';
import HandleServerError from '../../../../classes/HandleServerError';

const SinglePage = (props) => {
    const [detail, setDetail] = useState(null);
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [Reason, setReason] = useState(null);
    const [ReasonList, setReasonList] = useState(false);
    const [ReasonModal, setReasonModal] = useState(false);
    const history = useHistory();

    const formUrlEncoded = x =>
        Object.keys(x).reduce((p, c) => p + `&${c}=${encodeURIComponent(x[c])}`, '')

    useEffect(() => {
        getReasonList()
        getDetails()

        seo({
            title: props.match.params.tn
        });
    }, []);

    const formatName = (name) => {
        return HtmlParser(JSON.stringify(name).replace(/\\n/g, '<br>').replace(/"/g, ''));
    }

    const getDetails = () => {
        axios.post(apiDomain + 'panel/orderdetails.json', formUrlEncoded({
            token: localStorage.getItem("user_loged"),
            tn: props.match.params.tn.match(/\d/g).join(""),
            device: "mobile: web",
        }))
            .then(function (response) {
                const permition = [1, 2].includes(parseInt(response.data.order.order_status));
                const data = {
                    'status': response.data.order.order_status_title,
                    'order_status': permition,
                    'status': response.data.order.order_status,
                    'order_status_title': response.data.order.order_status_title,
                    'services': response.data.order.services_title,
                    'totalcweight': response.data.order.totalcweight,
                    'totalweight_title': response.data.order.totalweight_title,
                    'sender_city_title': response.data.order.sender_city_title,
                    'receiver_city_title': response.data.order.receiver_city_title,
                    'receiver_p': response.data.order.receiver_p,
                    'time_created_title': response.data.order.time_created_title,
                    'order_type_title': response.data.order.order_type_title,
                    'user_id_owner_title': response.data.order.user_id_owner_title ? formatName(response.data.order.user_id_owner_title) : '',
                    'receiver_p_title': response.data.order.receiver_p_title,
                    'totalweight': response.data.order.totalweight,
                    'totalvweight_title': response.data.order.totalvweight_title,
                    'priceunit_title': response.data.order.priceunit_title,
                    'expert_user_id_title': response.data.order.expert_user_id_title ? formatName(response.data.order.expert_user_id_title) : '',
                }
                setDetail(data);
            })
            .catch(function (error) {
            });
    }

    const DeleteOrder = (e) => {
        e.preventDefault();
        const text = e.target.elements.text
        if (!text || !Reason) {
            return false
        }

        setConfirmModal(true);
        setLoading(true);

        let formData = new FormData();
        let data = {
            'token': localStorage.getItem("user_loged"),
            'tn': props.match.params.tn,
            'text': text,
            'reason': Reason.key,
            'device': 'mobile: web',
            'pkg': 'express.psp.bpm.customer'
        };

        Object.keys(data).map(element => {
            formData.append(element, data[element]);
        });

        axios.post(apiDomain + 'panel/ordercancelstep1.json', formData)
            .then(function (response) {
                setLoading(false);
                if (response.data.status) {
                    toast.success(response.data.msg);
                    setConfirmModal(false);
                    getDetails()
                    history.push('/')
                } else {
                    alert(response.data.msg)
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const getReasonList = () => {
        let formData = new FormData();
        let data = {
            'token': localStorage.getItem("user_loged").toString(),
            'device': "mobile: web",
            'pkg': 'express.psp.bpm.customer'
        };

        Object.keys(data).map(element => {
            formData.append(element, data[element]);
        });

        axios.post(apiDomain + 'panel/formfields.json', formData)
            .then(function (response) {
                if (response.data.status) {
                    setReasonList(response.data.status_reason)
                }
            })
            .catch(function (error) {
                if (error && error.response) {
                    HandleServerError(history, error);
                }
            });
    }

    return (
        <>
            <Modal centered show={ReasonModal} onHide={() => {
                setReasonModal(false)
                setConfirmModal(true)
            }} animation={false}>
                <div className="noBorderModalContent listLimitModalWithSearch">
                    <div className="content">
                        <ul>
                            {ReasonList ? ReasonList.map(el => (
                                <li key={el.key} onClick={() => {
                                    setReason({
                                        key: el.key,
                                        value: el.value
                                    })
                                    setReasonModal(false)
                                    setConfirmModal(true)
                                }}>{el.value}</li>
                            )) : ''}
                        </ul>
                    </div>
                </div>
            </Modal>
            <Modal centered show={ConfirmModal} onHide={() => setConfirmModal(false)} animation={false} className="customModalBg">
                <Form onSubmit={DeleteOrder}>
                    <div className="noBorderModalContent invoicesPaymentModal">
                        <div className="content">
                            <p>لغو سفارش</p>
                            <div className="text"><small>لطفا دلیل لغو سفارش خود را انتخاب کنید</small></div>
                        </div>
                        <br />
                        <Form.Group>
                            <Form.Label>علت لغو را انتخاب کنید</Form.Label>
                            {/* <Form.Control className="text-center" onChange={event => setDeleteData({ ...DeleteData, reason: event.target.value })} placeholder='نام فارسی کالا' type="text" /> */}
                            <div className="form-control" onClick={() => {
                                setReasonModal(true)
                                setConfirmModal(false)
                            }}>
                                {Reason ? Reason.value : 'انتخاب کنید'}
                                <i className="fa fa-angle-down icon_field"></i>
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control as="textarea" name="text" rows={3} placeholder='توضیحات ...' />
                        </Form.Group>
                        <div className="buttonBlock">
                            <Button type="submit" variant="primary" size="lg" block>لغو سفارش</Button>
                            <LoadingButtonModal show={loading} />
                        </div>
                    </div>
                </Form>
            </Modal>
            <PageHeader prevLink="/" titleBold={true} titlePage={props.match.params.tn} edit={detail && detail.order_status ? '/order/' + props.match.params.tn + '/edit' : null} deleteOrder={detail && parseInt(detail.status) == 2 ? () => setConfirmModal(true) : false} />
            {detail ? (
                <div className="page_style_list singlePageOrder">
                    <button className="buttonStartChat">
                        <Link to={'/chat/' + props.match.params.tn}><img className="logo" src={window.location.origin + '/assets/image/comment.svg'} /></Link>
                    </button>
                    <div><Buttons /></div>
                    <div><Detail data={detail} /></div>
                </div>
            ) : <Loading />}
        </>
    );
}

export default PageLayout(withRouter(SinglePage));