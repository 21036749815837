import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { seo } from '../../../../classes/Seo';
import PageLayout from '../../../../hoc/PageLayout';
import PageHeader from '../../../common/PageHeader';
import { apiDomain } from '../../../../classes/Config';
import EditModal from './../create/parts/EditModal';
import SuccessModal from './../create/parts/SuccessModal';
import CrateOrderContext from './../create/Context';
import { useSelector } from 'react-redux';
import HandleServerError from '../../../../classes/HandleServerError';
import ReloadPage from '../../../common/ReloadPage';
import FirstDetail from './../create/parts/FirstDetail';
import ServicesModal from './../create/parts/ServicesModal';
import CountryModal from './../create/parts/CountryModal';
import OrderProductModal from './../create/parts/OrderProductModal';
import ListOrderProductsModal from './../create/parts/ListOrderProductsModal';
import Loading from '../../../common/Loading';

const EditOrder = (props) => {

    const uploadFile = useRef(null);
    const ProformaInvoiceElement = useRef(null);
    const PackingListElement = useRef(null);
    const history = useHistory();
    const ConnectionReducer = useSelector(state => state.ConnectionReducer);
    const orderProductData = {
        'title_en': null,
        'title_fa': null,
        'proforma_invoice': null,
        'packing_list': null,
        'count': null,
        'weight': null,
        'length': null,
        'width': null,
        'height': null,
        'images': {},
    };

    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }

    const [show, setShow] = useState(false);
    const [showCountryModalShow, setCountryModalShow] = useState(false);
    const [destinationCountryModalShow, setDestinationCountryModalShow] = useState(false);
    const [orderProductModal, setOrderProductModal] = useState(false);
    const [orderAddProductModal, setOrderAddProductModal] = useState(false);
    const [transport, settTransport] = useState(true);
    const [buy, setbuy] = useState(false);
    const [clearance, setclearance] = useState(false);
    const [country, setCountry] = useState(null);
    const [countryModel, setCountryModel] = useState(null);
    const [startingCity, setStartingCity] = useState(null);
    const [destinationCity, setDestinationCity] = useState(null);
    const [orderProduct, setOrderProduct] = useState(orderProductData);
    const [orderProductCollection, setOrderProductCollection] = useState({});
    const [OrderProductCollectionStatic, setOrderProductCollectionStatic] = useState({});
    const [editModalState, setEditModalState] = useState(false);
    const [ModalSuccess, setModalSuccess] = useState(false);
    const [tnAfterSuccess, settnAfterSuccess] = useState(null);
    const [Connection, setConnection] = useState(false);
    const [Order, setOrder] = useState(null);
    const [OrderProductDeleteIDs, setOrderProductDeleteIDs] = useState(null);
    const [UpdateProductsIDs, setUpdateProductsIDs] = useState(null);
    const [Permission, setPermission] = useState(false);
    const [StateGetDeleteImageProductsIDs, setStateGetDeleteImageProductsIDs] = useState(null);
    const [loading, setLoading] = useState(null);
    const [productEditRow, setProductEditRow] = useState({
        'key': null,
        'data': {}
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCountryModalClose = () => {
        setCountryModalShow(false);
        setCountry(countryModel);
    }
    const handleCountryModalShow = () => {
        setCountryModalShow(true)
        setCountry(countryModel);
    };

    const handleDestiCountryModalClose = () => setDestinationCountryModalShow(false);
    const handleDestiCountryModalShow = () => {
        setDestinationCountryModalShow(true);
        setCountry(countryModel);
    }

    const orderProductModalClose = () => {
        setOrderProductModal(false);
    }
    const orderProductModalShow = () => setOrderProductModal(true);

    const orderAddProductModalClose = () => setOrderAddProductModal(false);
    const orderAddProductModalShow = () => {
        setOrderAddProductModal(true);
        setOrderProductModal(false);
    };


    const formUrlEncoded = x =>
        Object.keys(x).reduce((p, c) => p + `&${c}=${encodeURIComponent(x[c])}`, '')


    const TypeServiceList = {
        'transport': 'حمل بین المللی',
        'buy': 'خرید کالا',
        'clearance': 'ترخیص'
    }

    const TypeServiceListActive = {
        'transport': transport,
        'buy': buy,
        'clearance': clearance
    };

    const printTypeServiceListActive = () => {
        let text = '';
        Object.keys(TypeServiceListActive).map(element => {
            if (TypeServiceListActive[element]) {
                text = text + ' - ' + TypeServiceList[element];
            }
        })
        const dots = text.length > 26 ? ' ...' : '';
        return text.substr(3, 26) + dots;
    }

    useEffect(() => {
        axios.post(apiDomain + 'panel/orderdetails.json', formUrlEncoded({
            token: localStorage.getItem("user_loged"),
            tn: props.match.params.tn.match(/\d/g).join(""),
            device: "mobile: web",
            pkg: "express.psp.bpm.customer"
        }))
            .then(function (response) {
                if (response.data) {
                    const data = response.data;
                    if ([1, 2].includes(parseInt(data.order.order_status))) {
                        setPermission([1].includes(parseInt(data.order.order_status)));
                        createDataProducts(data);
                        createDataProductStatic(data)
                        createDataServices(data.order.services_title);
                        createDataCountry(data.order);
                        setOrder(response.data.order);
                        setConnection(true);
                    } else {
                        history.push('/order/' + props.match.params.tn);
                    }
                }
            })
            .catch(function (error) {
                if (error && error.response) {
                    HandleServerError(history, error);
                } else {
                    setConnection(false);
                }
            });

        seo({
            title: "ویرایش سفارش " + props.match.params.tn
        });

        getCountry();
    }, []);

    const createDataProducts = (order) => {
        if (order.products) {
            let data = [];
            order.products.map(function (element, key) {
                data[element.id] = {
                    'id': element.id,
                    'count': element.quantity,
                    'title_fa': element.name,
                    'title_en': element.name_en,
                    'weight': element.oneweight,
                    'packing_list': element.attach_pl,
                    'proforma_invoice': element.attach_pi,
                    'length': element.length,
                    'width': element.width,
                    'height': element.height,
                    'images': null,
                    'images_saved': {}
                };
                if (element.images_array && Object.keys(element.images_array).length) {
                    element.images_array.map((img, key2) => {
                        data[element.id]['images_saved'][key2] = {
                            'id': img.id,
                            'op_id': img.op_id,
                            'filename': 'http://testbpm.2ms.ir/image/' + img.filename,
                        }
                        // console.log(img);
                    });
                }
            });
            setOrderProductCollection(data);
        }
    }

    const createDataProductStatic = (order) => {
        if (order.products) {
            let data = [];
            order.products.map(function (element, key) {
                data[element.id] = {
                    'id': element.id,
                    'count': element.quantity,
                    'title_fa': element.name,
                    'title_en': element.name_en,
                    'weight': element.oneweight,
                    'packing_list': element.attach_pl,
                    'proforma_invoice': element.attach_pi,
                    'length': element.length,
                    'width': element.width,
                    'height': element.height,
                    'images': null,
                    'images_saved': {}
                };
                if (element.images_array && Object.keys(element.images_array).length) {
                    element.images_array.map((img, key2) => {
                        data[element.id]['images_saved'][key2] = {
                            'id': img.id,
                            'op_id': img.op_id,
                            'filename': 'http://testbpm.2ms.ir/image/' + img.filename,
                        }
                        // console.log(img);
                    });
                }
            });
            setOrderProductCollectionStatic(data);
        }
    }

    const createDataServices = (services) => {
        services.split("/").map(element => {
            switch (element.trim()) {
                case 'حمل بین المللی':
                    settTransport(true);
                    break;
                case 'خرید کالا':
                    setbuy(true);
                    break;
                case 'ترخیص':
                    setclearance(true);
                    break;
            }
        });
    }

    const createDataCountry = (order) => {
        setStartingCity({
            'key': order.sender_city,
            'value': order.sender_city_title
        });
        setDestinationCity({
            'key': order.receiver_city,
            'value': order.receiver_city_title
        });
    };

    const getCountry = () => {
        axios.post(apiDomain + 'panel/citieslist.json', formUrlEncoded({
            token: localStorage.getItem("user_loged"),
            device: "mobile: web",
        }))
            .then(function (response) {
                if (response.data) {
                    setCountry(response.data.data);
                    setCountryModel(response.data.data);
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const handleStartingCity = (key, value) => {
        if (key) {
            setStartingCity({
                'key': key,
                'value': value,
            });
            setCountryModalShow(false);
        }
    }

    const handleDestinationCity = (key, value) => {
        if (key) {
            setDestinationCity({
                'key': key,
                'value': value,
            });
            setDestinationCountryModalShow(false);
        }
    }

    const uploadMultiFile = (event) => {
        let file = event.target.files[0];
        let lastModified = file.lastModified;
        let size = file.size;
        let images = orderProduct.images;
        if (file) {
            setOrderProduct({ ...orderProduct, images: { ...orderProduct.images, [lastModified + size]: file } });
        }
    }

    const ProformaInvoiceUpload = (event) => {
        let file = event.target.files[0];
        if (file) {
            setOrderProduct({ ...orderProduct, proforma_invoice: file })
        }
    }

    const PackingListUpload = (event) => {
        let file = event.target.files[0];
        if (file) {
            setOrderProduct({ ...orderProduct, packing_list: file })
        }
    }

    const UpdateAttachFileUpdate = (event, target, productID) => {
        let file = event.target.files[0];
        let data = orderProductCollection[productID];
        data[target] = file;
        setOrderProductCollection({ ...orderProductCollection, [productID]: data });
    }

    const uploadDeleteFile = (key) => {
        let imagesData = {};
        Object.keys(orderProduct.images).map(element => {
            if (element !== key) {
                imagesData[element] = orderProduct.images[element];
            }
        });
        setOrderProduct({ ...orderProduct, images: imagesData });
    }

    const orderProductAdded = (e) => {
        e.preventDefault();

        if (!orderProduct.title_fa) {
            alert('نام را وارد نمائید');
            return false;
        }
        if (!orderProduct.title_en) {
            alert('نام انگلیسی را وارد نمائید');
            return false;
        }
        if (!orderProduct.count) {
            alert('تعداد را وارد نمائید');
            return false;
        }
        if (!orderProduct.weight) {
            alert('وزن را وارد نمائید');
            return false;
        }

        const key = Object.keys(orderProductCollection).length + 1;
        setOrderProductCollection({ ...orderProductCollection, [(Math.floor(Math.random() * 10000) + 101) + key]: orderProduct })
        setOrderProduct(orderProductData);
        setOrderAddProductModal(false);
        setOrderProductModal(true);
    }

    const deleteOrderProduct = (keyOrderProduct) => {
        let orderProductsData = {};
        Object.keys(orderProductCollection).map(element => {
            if (element !== keyOrderProduct) {
                orderProductsData[element] = orderProductCollection[element];
            } else {
                let id = orderProductCollection[element].id;
                setOrderProductDeleteIDs({ ...OrderProductDeleteIDs, [id]: id })
            }
        });
        setOrderProductCollection(orderProductsData);
    }

    const HandleDeleteOrderProduct = () => {
        if (OrderProductDeleteIDs) {
            Object.keys(OrderProductDeleteIDs).map(element => {
                axios.post(apiDomain + 'panel/orderdeleteproducts.json', formUrlEncoded({
                    token: localStorage.getItem("user_loged"),
                    id: OrderProductDeleteIDs[element],
                    device: 'mobile: web',
                    pkg: 'express.psp.bpm.customer',
                }))
                    .then(function (response) { })
                    .catch(function (error) { });
            });
        }
    }

    const UpdateAllProduct = () => {
        if (UpdateProductsIDs) {
            Object.keys(UpdateProductsIDs).map(element => {
                if (orderProductCollection[element]) {
                    let formData = new FormData();
                    let data = {
                        token: localStorage.getItem("user_loged"),
                        id: element,
                        order_id: Order.id,
                        oneweight: orderProductCollection[element]['weight'],
                        name: orderProductCollection[element]['title_fa'],
                        name_en: orderProductCollection[element]['title_en'],
                        quantity: orderProductCollection[element]['count'],
                        attach_pl: orderProductCollection[element]['packing_list'],
                        attach_pi: orderProductCollection[element]['proforma_invoice'],
                        attach_pi_delete: !orderProductCollection[element]['proforma_invoice'] ? 1 : 0,
                        attach_pl_delete: !orderProductCollection[element]['packing_list'] ? 1 : 0,
                        length: orderProductCollection[element]['length'],
                        width: orderProductCollection[element]['width'],
                        height: orderProductCollection[element]['height'],
                        device: 'mobile: web',
                        pkg: 'express.psp.bpm.customer',
                    };

                    let images = orderProductCollection[element].images;
                    let opID = element;

                    Object.keys(data).map(el => {
                        formData.append(el, data[el]);
                    });

                    axios.post(apiDomain + 'panel/ordereditproducts.json', formData)
                        .then(function (response) {
                            uploadImageOrderProduct(images, opID);
                        })
                        .catch(function (error) { });
                }
            });
        }
    }

    const handleForm = (e) => {
        e.preventDefault();
        setLoading(true);
        if ([1].includes(parseInt(Order.order_status))) {
            UpdateAllProduct();
            HandleDeleteImageProducts();
            setModalSuccess(true);
        } else {
            if (!(transport || buy || clearance)) {
                alert('نوع پرداخت را وارد نمائید');
                return false;
            }
            if (!startingCity) {
                alert('کشور مبدا را وارد نمائید');
                return false;
            }
            if (!destinationCity) {
                alert('کشور مقصد را وارد نمائید');
                return false;
            }
            if (!Object.keys(orderProductCollection).length) {
                toast.error('هیچ کالایی برای سفارش ثبت نکرده اید');
                return false;
            }

            let service = '';
            Object.keys(TypeServiceListActive).map(element => {
                if (TypeServiceListActive[element]) {
                    service = service + ',' + element;
                }
            });
            service = service.substr(1, 1000);

            axios.post(apiDomain + 'panel/ordereditstep1.json', formUrlEncoded({
                tn: Order.tn,
                services: service,
                token: localStorage.getItem("user_loged"),
                sender_city: startingCity.key,
                receiver_city: destinationCity.key,
                device: 'mobile: web',
                pkg: 'express.psp.bpm.customer',
            }))
                .then(function (response) {
                    if (response.data.status) {
                        HandleQueryApiOrderProduct(response.data.order_id);
                        HandleDeleteOrderProduct();
                        setModalSuccess(true);
                        settnAfterSuccess(response.data.order_tn);
                        UpdateAllProduct();
                        HandleDeleteImageProducts();
                    } else {
                        toast.error(response.data.msg);
                    }
                })
                .catch(function (error) {
                    if (error && error.response) {
                        HandleServerError(history, error);
                    } else {
                        setConnection(false);
                    }
                });
        }

    }

    const uploadImageOrderProduct = (images, opID) => {
        Object.keys(images).map(element => {
            let formData = new FormData();
            let data = {
                'token': localStorage.getItem("user_loged"),
                'op_id': opID,
                'photo': images[element],
                'device': 'mobile: web',
                'pkg': 'express.psp.bpm.customer',
            };

            Object.keys(data).map(element => {
                formData.append(element, data[element]);
            });

            axios.post(apiDomain + 'panel/orderproductsaddimage.json', formData, config)
                .then(function (response) {
                    // console.log(response);
                }).catch(function (error) { });

        });
    }

    const HandleQueryApiOrderProduct = (orderID) => {
        Object.keys(orderProductCollection).map(element => {
            let formData = new FormData();
            if (orderProductCollection[element].id) {
                return false;
            }

            let data = {
                'order_id': orderID,
                'token': localStorage.getItem("user_loged"),
                'name': orderProductCollection[element].title_fa,
                'name_en': orderProductCollection[element].title_en,
                'quantity': orderProductCollection[element].count,
                'oneweight': orderProductCollection[element].weight,
                'attach_pi': orderProductCollection[element].proforma_invoice,
                'attach_pl': orderProductCollection[element].packing_list,
                'length': orderProductCollection[element].length,
                'width': orderProductCollection[element].width,
                'height': orderProductCollection[element].height,
                'device': 'mobile: web',
                'pkg': 'express.psp.bpm.customer'
            };

            Object.keys(data).map(element => {
                formData.append(element, data[element]);
            });

            axios.post(apiDomain + 'panel/orderaddproducts.json', formData, config)
                .then(function (response) {
                    if (response.data.status) {
                        let images = orderProductCollection[element].images;
                        let opID = response.data.op_id;
                        if (Object.keys(images).length) {
                            uploadImageOrderProduct(images, opID);
                        }
                        setOrderProductCollection({});
                        settTransport(true);
                        setbuy(false);
                        setclearance(false);
                        setStartingCity(null);
                        setDestinationCity(null);
                    }
                })
                .catch(function (error) {
                    // console.log(error);
                });
        });
    }

    const GetDeleteImageProductsIDs = (element) => {
        setStateGetDeleteImageProductsIDs({ ...StateGetDeleteImageProductsIDs, [element.id]: element });
        if (orderProductCollection[element.op_id] && Object.keys(orderProductCollection[element.op_id].images_saved).length) {
            let data = {
                'images_saved': [{}]
            };
            let product = orderProductCollection[element.op_id];
            Object.keys(product).map(element => {
                if (element !== 'images_saved') {
                    data[element] = product[element];
                }
            });

            Object.keys(product['images_saved']).map((element2, key) => {
                let id = product['images_saved'][element2].id;
                if (id !== element.id) {
                    data.images_saved[key] = product['images_saved'][element2];
                }
            });

            setOrderProductCollection({ ...orderProductCollection, [element.op_id]: data });
            setProductEditRow(productEditRow);
        }
    }

    const DeleteAttach = (element, target) => {
        const product = orderProductCollection[element.id];
        let data = {};
        Object.keys(product).map(el => {
            data[el] = product[el];
        })
        data[target] = 1;
        GetUpdateProductsIDs(element.id);
        setOrderProductCollection({ ...orderProductCollection, [element.id]: data });
    }

    const HandleDeleteImageProducts = (element) => {
        if (StateGetDeleteImageProductsIDs) {
            Object.keys(StateGetDeleteImageProductsIDs).map(element => {
                let image = StateGetDeleteImageProductsIDs[element];
                let formData = new FormData();
                let data = {
                    'token': localStorage.getItem("user_loged"),
                    'id': image.op_id,
                    'index': image.id,
                    'device': 'mobile: web',
                    'pkg': 'express.psp.bpm.customer',
                };

                Object.keys(data).map(element => {
                    formData.append(element, data[element]);
                });

                axios.post(apiDomain + 'panel/orderproductsdeleteimage.json', formData)
                    .then(function (response) {
                    }).catch(function (error) { });
            });
        }
    }

    const searchCountry = (keyword) => {
        if (keyword) {
            let keywords = keyword.toLowerCase();
            let formData = new FormData();
            let data = {
                'token': localStorage.getItem("user_loged"),
                'query': keywords,
                'device': 'mobile: web',
                'pkg': 'express.psp.bpm.customer',
            };

            Object.keys(data).map(element => {
                formData.append(element, data[element]);
            });

            axios.post(apiDomain + 'panel/citieslist.json', formData)
                .then(function (response) {
                    setCountry(response.data.data);
                }).catch(function (error) { });

        } else {
            setCountry(countryModel);
        }
    }

    const HandleEditModalClose = () => {
        setEditModalState(false);
        setProductEditRow({
            'key': null,
            'data': {}
        });
    }

    const handleEditProductsFunc = (product, key) => {
        setOrderProductCollection({ ...orderProductCollection, [key]: product });
        HandleEditModalClose();
    }

    const GetUpdateProductsIDs = (productID, key) => {
        setUpdateProductsIDs({ ...UpdateProductsIDs, [productID]: productID });
    }

    const validateOrderProductFields = (data = null) => {
        if(!data){
            return false;
        }

        let error = true;
        if (!data.title_fa) {
            alert('نام را وارد نمائید');
            error = false;
            return false;
        }
        if (!data.title_en) {
            alert('نام انگلیسی را وارد نمائید');
            error = false;
            return false;
        }
        if (!data.count) {
            alert('تعداد را وارد نمائید');
            error = false;
            return false;
        }
        if (!data.weight) {
            alert('وزن را وارد نمائید');
            error = false;
            return false;
        }
        if (data.length || data.width || data.height) {
            if (!data.length) {
                alert('طول را وارد نمائید');
                error = false;
                return false;
            }
            if (!data.width) {
                alert('عرض را وارد نمائید');
                error = false;
                return false;
            }
            if (!data.height) {
                alert('ارتفاع را وارد نمائید');
                error = false
                return false;;
            }
        }
        return error;
    }


    const FirstDetailData = {
        'handleForm': handleForm,
        'handleShow': handleShow,
        'transport': transport,
        'buy': buy,
        'clearance': clearance,
        'printTypeServiceListActive': printTypeServiceListActive,
        'handleCountryModalShow': handleCountryModalShow,
        'startingCity': startingCity,
        'handleDestiCountryModalShow': handleDestiCountryModalShow,
        'destinationCity': destinationCity,
        'orderProductModalShow': orderProductModalShow,
        'orderProductCollection': orderProductCollection,
    };

    const serviceModalData = {
        'show': show,
        'handleClose': handleClose,
        'settTransport': settTransport,
        'TypeServiceList': TypeServiceList,
        'setbuy': setbuy,
        'setclearance': setclearance,
        'transport': transport,
        'buy': buy,
        'clearance': clearance,
    }

    const ListOrderProductsData = {
        'orderProductModal': orderProductModal,
        'orderProductModalClose': orderProductModalClose,
        'orderAddProductModalShow': orderAddProductModalShow,
        'orderProductCollection': orderProductCollection,
        'setEditModalState': setEditModalState,
        'setProductEditRow': setProductEditRow,
        'deleteOrderProduct': deleteOrderProduct,
    }

    const OrderProductModalData = {
        'orderAddProductModal': orderAddProductModal,
        'orderAddProductModalClose': orderAddProductModalClose,
        'orderProductAdded': orderProductAdded,
        'setOrderProduct': setOrderProduct,
        'orderProduct': orderProduct,
        'ProformaInvoiceElement': ProformaInvoiceElement,
        'PackingListElement': PackingListElement,
        'uploadFile': uploadFile,
        'uploadDeleteFile': uploadDeleteFile,
        'ProformaInvoiceUpload': (event) => ProformaInvoiceUpload(event),
        'PackingListUpload': (event) => PackingListUpload(event),
        'uploadMultiFile': (event) => uploadMultiFile(event),
    };

    const EditModalData = {
        'UpdateAttachFileUpdate': UpdateAttachFileUpdate,
        'OrderProductCollectionStatic': OrderProductCollectionStatic
    };
    return (
        <>
            {ConnectionReducer ? (
                <>
                    {Connection ? (
                        <>
                            <PageHeader prevLink="/" titlePage={props.match.params.tn + ' - ویرایش سفارش'} />
                            <CrateOrderContext.Provider value={{
                                EditModalState: editModalState,
                                handleEditProducts: handleEditProductsFunc,
                                EditModalClose: HandleEditModalClose,
                                productEditRowData: productEditRow,
                                FirstDetail: FirstDetailData,
                                serviceModalData: serviceModalData,
                                ListOrderProductsData: ListOrderProductsData,
                                OrderProductModalData: OrderProductModalData,
                                GetUpdateProductsIDs: GetUpdateProductsIDs,
                                editDisable: Permission,
                                buttonAddPrduct: Order && Order.order_status != 1,
                                GetDeleteImageProductsIDs: GetDeleteImageProductsIDs,
                                DeleteAttach: DeleteAttach,
                                EditModalData: EditModalData,
                                validateOrderProductFieldsCo: validateOrderProductFields
                            }}>
                                <EditModal data={productEditRow} isEdit={true} />
                                <SuccessModal modalState={ModalSuccess} tnOrder={tnAfterSuccess} modalClose={() => setModalSuccess(false)} msg="سفارش مورد نظر شما ویرایش شد" />
                                <ServicesModal />
                                <CountryModal CountryModalData={{
                                    'showCountryModalShow': showCountryModalShow,
                                    'handleCountryModalClose': handleCountryModalClose,
                                    'searchCountry': searchCountry,
                                    'country': country,
                                    'handleStartingCity': handleStartingCity,
                                }} />
                                <CountryModal CountryModalData={{
                                    'showCountryModalShow': destinationCountryModalShow,
                                    'handleCountryModalClose': handleDestiCountryModalClose,
                                    'searchCountry': searchCountry,
                                    'country': country,
                                    'handleStartingCity': handleDestinationCity,
                                }} />
                                <ListOrderProductsModal isEdit={true} />
                                <OrderProductModal />
                                <FirstDetail buttonCaption="ویرایش سفارش" loading={loading} />
                            </CrateOrderContext.Provider>
                        </>
                    ) : <Loading />}
                </>
            ) : <ReloadPage />}
        </>
    );
}

export default PageLayout(withRouter(EditOrder));