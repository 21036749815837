import React, { Component } from 'react';
import CompleteLayout from '../../hoc/CompleteLayout';

class NextVersion extends Component {
    render() { 
        return (
            <>
            <div className="page_style comming_soon">این امکان در نسخه های بعدی اضافه خواهد شد</div>
            </>
        );
    }
}
 
export default CompleteLayout(NextVersion);