import {combineReducers} from 'redux';
import { DrawerMenuReducer } from './DrawerMenuReducer';
import { LoginReducer } from './LoginReducer';
import { PersonReducer } from './PersonReducer';
import { ConnectionReducer } from './ConnectionReducer';

export const reducers = combineReducers({
    DrawerMenuReducer,
    LoginReducer,
    PersonReducer,
    ConnectionReducer
});