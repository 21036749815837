import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { seo } from '../../../../classes/Seo';
import CompleteLayout from '../../../../hoc/CompleteLayout';
import ReloadPage from '../../../common/ReloadPage';
import Charge from './parts/Charge';
import CurrencyList from './parts/CurrencyList';
import HistoryTransactions from './parts/HistoryTransactions';

const Wallet = () => {

    const ConnectionReducer = useSelector(state => state.ConnectionReducer);

    useEffect(() => {
        seo({
            title: 'کیف پول'
        });
    }, []);

    return (
        <>
            {ConnectionReducer ? (
                <div className="page_style_list walletPage">
                    <CurrencyList />
                    <Charge />
                    <HistoryTransactions />
                </div>
            ) : <ReloadPage />}
        </>
    );
}

export default CompleteLayout(withRouter(Wallet));