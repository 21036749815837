import React from 'react';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';
import Sidebar from '../components/common/Sidebar';

const CompleteLayout = (RootComponent) => {
    return props => (
        <>
            <Header />
            <Sidebar />

            <RootComponent/>
            <Footer />
        </>
    );
}

export default CompleteLayout;