import React, { useEffect } from "react";
import Login from "./components/pages/loginComponent/FormEnterMobile";
import { Redirect, Route, Switch, useHistory, withRouter } from "react-router-dom";
import CreateNewOrder from "./components/pages/orderComponent/create/CreateNewOrder";
import NextVersion from "./components/common/NextVersion";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { UpdatePersonCurrenLogin } from "./actions/PersonAction";
import ListOrders from "./components/pages/orderComponent/ListOrders";
import SinglePage from "./components/pages/orderComponent/single/SinglePage";
import Wallet from "./components/pages/orderComponent/wallet/Wallet";
import { ToastContainer } from "react-toastify";
import ListActions from "./components/pages/actionComponent/list/ListActions";
import EditProfile from "./components/pages/profileComponent/EditProfile";
import ListOrderProducts from "./components/pages/orderComponent/orderProducts/ListOrderProducts";
import Tracking from "./components/pages/orderComponent/tracking/Tracking";
import Invoices from "./components/pages/orderComponent/invoices/Invoices";
import { apiDomain } from "./classes/Config";
import DevelopRibbon from "./components/common/DevelopRibbon";
import CheckConnection from "./components/common/CheckConnection";
import HandleServerError from "./classes/HandleServerError";
import EditOrder from "./components/pages/orderComponent/update/EditOrder";
import ReloadPage from "./components/common/ReloadPage";
import ListChats from "./components/pages/chatComponent/list/ListChats";
import SingleChat from "./components/pages/chatComponent/single/SingleChat";
import ShipingCost from "./components/pages/inquiryComponent/ShipingCost";

const formUrlEncoded = (x) =>
  Object.keys(x).reduce((p, c) => p + `&${c}=${encodeURIComponent(x[c])}`, "");

const App = () => {
  const loggedIn = localStorage.getItem("user_loged");
  const dispatch = useDispatch();
  const history = useHistory();
  const PersonReducer = useSelector(state => state.PersonReducer);
  const ConnectionReducer = useSelector(state => state.ConnectionReducer);

  useEffect(() => {

    if (loggedIn && !PersonReducer) {
      axios.post(apiDomain + "/panel/userget.json",
        formUrlEncoded({
          token: loggedIn,
          device: "mobile: web",
        })
      )
        .then(function (response) {
          dispatch(UpdatePersonCurrenLogin(response.data.person));
        })
        .catch(function (error) {
          if (error && error.response) {
            HandleServerError(history, error);
          }
        });
    }
  }, []);

  return (
    <>
      <CheckConnection />
      <DevelopRibbon />
      <ToastContainer closeButton={false} position="bottom-center-custom" autoClose={3000} hideProgressBar={true} />
      {ConnectionReducer ? (
        <Switch>
          <Route
            path="/"
            exact
            render={() => (!loggedIn ? <Redirect to="/login" /> : <ListOrders />)}
          />
          <Route
            path="/order/create"
            exact
            render={() =>
              !loggedIn ? <Redirect to="/login" /> : <CreateNewOrder />
            }
          />
          <Route
            path="/order/:tn"
            exact
            render={() =>
              !loggedIn ? <Redirect to="/login" /> : <SinglePage />
            }
          />
          <Route
            path="/search/:tn"
            exact
            component={Tracking}
          />
          <Route
            path="/order/:tn/edit"
            exact
            render={() =>
              !loggedIn ? <Redirect to="/login" /> : <EditOrder />
            }
          />
          <Route
            path="/shipingcost"
            exact
            render={() =>
              !loggedIn ? <Redirect to="/login" /> : <ShipingCost />
            }
          />
          <Route
            path="/order/:tn/orderproducts"
            exact
            render={() =>
              !loggedIn ? <Redirect to="/login" /> : <ListOrderProducts />
            }
          />
          <Route
            path="/order/:tn/tracking"
            exact
            render={() =>
              !loggedIn ? <Redirect to="/login" /> : <Tracking />
            }
          />
          <Route
            path="/order/:tn/invoices"
            exact
            render={() =>
              !loggedIn ? <Redirect to="/login" /> : <Invoices />
            }
          />
          <Route
            path="/commingsoon"
            exact
            render={() =>
              !loggedIn ? <Redirect to="/login" /> : <NextVersion />
            }
          />
          <Route
            path="/wallet"
            exact
            render={() =>
              !loggedIn ? <Redirect to="/login" /> : <Wallet />
            }
          />
          <Route
            path="/actions"
            exact
            render={() =>
              !loggedIn ? <Redirect to="/login" /> : <ListActions />
            }
          />
          <Route
            path="/chat"
            exact
            render={() =>
              !loggedIn ? <Redirect to="/chat" /> : <ListChats />
            }
          />
          <Route
            path="/chat/:tn"
            exact
            render={() =>
              !loggedIn ? <Redirect to="/chat" /> : <SingleChat />
            }
          />
          <Route
            path="/profile"
            exact
            render={() =>
              !loggedIn ? <Redirect to="/login" /> : <EditProfile />
            }
          />
          <Route path="/login" component={Login} />
        </Switch>
      ) : <ReloadPage />}
    </>
  );
};

export default withRouter(App);
