import React, { Component, createRef } from 'react';
import axios from "axios";
import { apiDomain } from '../../../../classes/Config';
import Loading from '../../../common/Loading';
import { withRouter } from 'react-router-dom';
import PageLayout from '../../../../hoc/PageLayout';
import { seo } from '../../../../classes/Seo';
import PageHeader from '../../../common/PageHeader';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import HandleServerError from '../../../../classes/HandleServerError';
import Attach from './parts/Attach';
import ReloadPage from '../../../common/ReloadPage';
import { ReactMic } from 'react-mic';
import getBlobDuration from 'get-blob-duration';
import ReactPlayer from 'react-player';
import { store } from '../../../../store/Index';


class SingleChat extends Component {

    constructor(props) {
        super(props);
        this.variableMessage = {
            'TextMessage': null,
            'FileMessage': null,
            'VoiceMessage': null
        };
        this.scrollPage = createRef()
        // const history = this.props.history;
        this.TextMessageModalElement = createRef();
        this.TextMessageElement = createRef();
        this.uploadFile = createRef();
        this.triggerSubmit = createRef();
        this.elementMessages = createRef();
        // const CaptionFeild = createRef(null);

        this.state = {
            Chats: null,
            StartScroll: false,
            NewMessage: false,
            NewMessageIncoming: null,
            Message: this.variableMessage,
            Connected: true,
            PerPage: 20,
            Record: false,
            ImageModal: null,
            AudioPlay: null,
            AudioPlayRuning: false,
            ListChatsLength: 0,
            ListUploadMessage: [],
            PersonReducer: null,
            loadMoreAccess: true,
            scrollTo: 0
        };
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.PersonReducer !== prevState.PersonReducer) {
            this.getChats()
        }
        if (this.state.Chats !== prevState.Chats && !this.state.StartScroll) {
            setTimeout(() => {
                this.scrollDown()
            }, 500);
            this.setState({ StartScroll: true })
        }
    }

    componentWillUnmount() {
        this.setState({ Chats: null })
    }

    componentDidMount() {

        setTimeout(() => {
            this.setState({ PersonReducer: store.getState().PersonReducer })
        }, 1000);

        setInterval(() => {
            this.getChats(true)
        }, 8000);

        seo({
            title: this.props.match.params.tn
        });

        return () => {
            this.setState({ Chats: null });
        }
    }

    getChats = () => {
        if (this.state.PersonReducer && this.props.match.params.tn) {
            let formData = new FormData();
            let data = {
                'token': localStorage.getItem("user_loged"),
                'device': 'mobile: web',
                'page': 1,
                'per_page': 10000,
                'tn': ['پشتیبانی'].includes(this.props.match.params.tn) ? 0 : this.props.match.params.tn,
                'user_id': this.state.PersonReducer.user_id_connect,
                'pkg': 'express.psp.bpm.customer'
            };

            Object.keys(data).map(element => {
                formData.append(element, data[element]);
            });

            const self = this;
            const props = this.props;

            axios.post(apiDomain + 'panel/chatmessageslist.json', formData)
                .then(function (response) {
                    if (response.data.status) {
                        if (self.state.Chats) {
                            const objDiv = self.scrollPage.current;
                            let endHight = objDiv.scrollHeight - objDiv.offsetHeight;
                            if (!(objDiv.scrollTop >= endHight - 100) && self.state.NewMessage && response.data.data.length != self.state.NewMessage) {
                                self.setState({ NewMessageIncoming: self.state.NewMessageIncoming + response.data.data.length - self.state.NewMessage });
                            }
                            if (objDiv.scrollTop >= endHight - 100) {
                                objDiv.scrollTo({
                                    top: objDiv.scrollHeight,
                                    left: 0,
                                    behavior: 'smooth'
                                });
                            }
                        }
                        let data = response.data.data;
                        self.setState({ NewMessage: response.data.data.length });
                        if (['پشتیبانی'].includes(self.props.match.params.tn)) {
                            data.push({
                                attach_file: null,
                                attach_file_title: null,
                                boxdad: "boxchat",
                                changeboxstatus: "active",
                                expert_manager: "0",
                                id: "2338",
                                msg_type: "1",
                                order_id: "0",
                                read: true,
                                text: "سلام, چطور میتونم کمکتون کنم ؟",
                                time_created: new Date().getTime() / 1000,
                                time_created_title: "سه شنبه ۷ بهمن ۱۳۹۹    ۱۶:۲۱:۰۲",
                                user_id: "0",
                                user_id_created: "1"
                            });
                        }
                        let dat = [];
                        data.map(el => {
                            dat[el.id] = el;
                        })
                        if (!self.state.Chats) {
                            self.setState({ Chats: data.reverse() });
                            self.setState({ ListChatsLength: dat.length })
                        } else {
                            let MinKey = self.state.Chats.length
                            let MaxKey = data.length
                            data.reverse().slice(MinKey, MaxKey).map(el => {
                                if (el.user_id != el.user_id_created) {
                                    self.setState({ Chats: [...self.state.Chats, el] })
                                }
                            })
                            if (MinKey != MaxKey) {
                            }
                        }
                        self.setState({ Connected: true });

                    }
                    if (!localStorage.getItem("supportRead")) {
                        localStorage.setItem("supportRead", true);
                    }
                })
                .catch(function (error) {
                    if (error && error.response) {
                        HandleServerError(props.history, error);
                    }
                    self.setState({ Connected: false });
                });
        }
    }

    LoadMore = () => {
        const objDiv = this.scrollPage.current;
        if (!objDiv) {
            return false
        }
        let prevScrollHeight = objDiv.scrollHeight
        if (objDiv.scrollTop === 0 && this.state.StartScroll && this.state.loadMoreAccess) {
            this.setState({ loadMoreAccess: false })
            setTimeout(() => {
                // let lastElement = this.state.Chats[this.state.Chats.length - this.state.PerPage].id
                this.setState({ PerPage: this.state.PerPage + 20 });
                this.setState({ loadMoreAccess: true })
                objDiv.scrollTop = objDiv.scrollHeight - prevScrollHeight;
            }, 200);
        }
    }

    scrollDown = () => {
        try {
            // if (objDiv.scrollTop >= endHight) {}
            const objDiv = this.scrollPage.current;
            let endHight = objDiv.scrollHeight - objDiv.offsetHeight;
            objDiv.scrollTo({
                top: objDiv.scrollHeight,
                left: 0,
                behavior: 'smooth'
            });
            this.setState({ NewMessageIncoming: null });
            this.seenMessage();
            if (!this.state.StartScroll) {
                this.setState({ StartScroll: true });
            }
        } catch (error) {
            // console.log(error);
        }
    }

    seenMessage = () => {
        const self = this
        let id = null;
        this.state.Chats.map(el => {
            if (el.user_id != el.user_id_created) {
                id = el.id;
            }
        });

        if (!id) {
            return false;
        }

        let formData = new FormData();
        let data = {
            'token': localStorage.getItem("user_loged"),
            'device': 'mobile: web',
            'id': id,
            'all': true,
            'pkg': 'express.psp.bpm.customer'
        };

        Object.keys(data).map(element => {
            formData.append(element, data[element]);
        });

        axios.post(apiDomain + 'panel/chatmessageread.json', formData)
            .then(function (response) {
            })
            .catch(function (error) {
                if (error && error.response) {
                    HandleServerError(self.history, error);
                }
            });
    }

    handleForm = (e) => {
        e.preventDefault();
        let TextMessageModal = this.TextMessageModalElement.current ? this.TextMessageModalElement.current.value ? this.TextMessageModalElement.current.value : null : null
        let textMsg = this.TextMessageElement.current ? this.TextMessageElement.current.value : null;
        this.setState({ ImageModal: null });

        this.setState({ Message: this.variableMessage });
        if (this.uploadFile && this.uploadFile.current) this.uploadFile.current.value = "";
        if (this.CaptionFeild && this.CaptionFeild.current) this.CaptionFeild.current.value = "";
        if (!textMsg && !this.state.Message.FileMessage) {
            return false;
        }

        let data = {
            'text': this.state.Message.TextMessage && this.state.Message.TextMessage !== true ? this.state.Message.TextMessage : textMsg ? textMsg : TextMessageModal ? TextMessageModal : '',
            'type': this.state.Message.FileMessage ? 2 : 1,
        };
        if (this.state.Message.FileMessage) {
            const fileUpload = this.state.Message.FileMessage;
            data['file'] = fileUpload;
            data['size'] = this.state.Message.FileMessage.size

            if (fileUpload.type.toLowerCase().match(/.(jpg|jpeg|png|gif)$/i)) {
                let reader = new FileReader();
                reader.readAsDataURL(fileUpload);
                const query = (data) => this.queryChatApi(data)
                reader.onload = function (e) {
                    const image = new Image();
                    image.src = e.target.result;
                    image.onload = function () {
                        data['width'] = parseInt(this.width)
                        data['height'] = parseInt(this.height)
                        query(data);
                    }
                }
            } else {
                this.queryChatApi(data);
            }

        } else {
            this.queryChatApi(data);
        }
    }

    queryChatApi = (dataPass) => {

        const id = Math.random()
        this.addToChat(dataPass, id);

        let formData = new FormData();
        let data = {
            'token': localStorage.getItem("user_loged"),
            'device': 'mobile: web',
            'tn': ['پشتیبانی'].includes(this.props.match.params.tn) ? 0 : this.props.match.params.tn,
            'user_id': this.state.PersonReducer.user_id_connect,
            'pkg': 'express.psp.bpm.customer'
        };

        if (dataPass) {
            Object.keys(dataPass).forEach(element => {
                data[element] = dataPass[element]
            });
        }

        Object.keys(data).map(element => {
            formData.append(element, data[element]);
        });

        const self = this
        axios.post(apiDomain + 'panel/chatsendmessage.json', formData)
            .then(function (response) {
                if (response.data.status) {
                    let listUploads = self.state.ListUploadMessage
                    const index = listUploads.indexOf(id);
                    if (index > -1) {
                        listUploads.splice(index, 1);
                    }

                    self.setState({
                        Message: self.variableMessage,
                        NewMessageIncoming: null,
                        ListUploadMessage: listUploads
                    });

                } else {
                    toast.error(response.data.msg);
                }
            })
            .catch(function (error) {
                if (error && error.response) {
                    HandleServerError(this.history, error);
                }
            });
    }

    dateResult = (milliseconds) => {
        var hours = milliseconds / (1000 * 60 * 60);
        var absoluteHours = Math.floor(hours);
        var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

        var minutes = (hours - absoluteHours) * 60;
        var absoluteMinutes = Math.floor(minutes);
        var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

        var seconds = (minutes - absoluteMinutes) * 60;
        var absoluteSeconds = Math.floor(seconds);
        var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

        return m + ':' + s;
    }

    onStop = (recordedBlob) => {
        const fileOfBlob = new File([recordedBlob.blob], 'record.mp3');
        const self = (data) => this.queryChatApi(data);
        (async function () {
            const duration = await getBlobDuration(recordedBlob.blob)
            let data = {
                'file': fileOfBlob,
                'duration': duration * 1000,
                'size': recordedBlob.blob.size,
                'type': 3,
            };
            self(data)
        })()

        this.setState({ Record: false });
    }

    addToChat = (data, id) => {
        if (!data || !id) {
            return false
        }

        if (this.TextMessageElement.current) this.TextMessageElement.current.value = "";

        const File_Message = data.file ? data.file : null
        const File_Text = data.text ? data.text : ''
        const File_Size = data.size ? data.size : null
        const File_Name = File_Message ? File_Message.name : null
        const File_Type = data.type ? data.type : null
        const File_Duration = data.duration ? data.duration : null
        const fileType = File_Message ? /[^.]+$/.exec(File_Message.name)[0] : null

        let customData = {
            attach_file: File_Name,
            attach_file_title: File_Message ? URL.createObjectURL(File_Message) : null,
            attach_file_type: fileType,
            boxdad: "boxchat",
            changeboxstatus: "active",
            expert_manager: "0",
            id: id,
            msg_type: File_Type,
            order_id: "0",
            result: {
                message: {
                    id: "3314",
                    text: null,
                    time: "1614515601",
                    userId: "0",
                    voice: {
                        duration: File_Duration,
                        name: File_Name,
                        src: File_Message ? URL.createObjectURL(File_Message) : null
                    }
                }
            },
            read: false,
            text: File_Text,
            time_created: new Date().getTime() / 1000,
            time_created_title: "سه شنبه ۷ بهمن ۱۳۹۹    ۱۶:۲۱:۰۲",
            user_id: this.state.PersonReducer.user_id_connect,
            user_id_created: this.state.PersonReducer.user_id_connect,
            alive: true
        }

        this.setState({
            ListUploadMessage: [...this.state.ListUploadMessage, id],
            Chats: [...this.state.Chats, customData]
        })

        setTimeout(() => {
            this.scrollDown()
        }, 100);
    }

    render() {

        return (
            <>
                <PageHeader prevLink="/chat" titlePage={this.props.match.params.tn} />
                {this.state.Connected ? (
                    <>
                        {this.state.Chats ? (
                            <div className="page_style_list chat--page singleChat">
                                <ReactPlayer
                                    playing={this.state.AudioPlayRuning}
                                    url={this.state.AudioPlay}
                                    onEnded={() => {
                                        this.setState({ AudioPlayRuning: false })
                                    }}
                                />
                                <div className="messages">
                                    {this.state.NewMessageIncoming ? (
                                        <button className="scrollDownButton" onClick={() => this.scrollDown()}>
                                            <div className="message">{this.state.NewMessageIncoming}</div>
                                            <i className="ti-angle-double-down"></i>
                                        </button>
                                    ) : ''}
                                    <ul ref={this.scrollPage} onScroll={() => this.LoadMore()}>
                                        {this.state.Chats.slice(this.state.Chats.length - this.state.PerPage, this.state.Chats.length).map((el, key) => {
                                            // {this.state.Chats.map((el, key) => {
                                            let date = new Date(parseInt(el.time_created) * 1000);
                                            let options = {
                                                hour: "numeric",
                                                minute: "2-digit",
                                            };
                                            let position = el.user_id != el.user_id_created;
                                            let audio = el.attach_file_type && ['mp3', 'wma', 'mp4'].includes(el.attach_file_type.toLowerCase());
                                            let loaded = Object.keys(this.state.ListUploadMessage).length && this.state.ListUploadMessage.includes(el.id);

                                            return (
                                                <li key={key} ref={this.state.loadMoreAccess ? this.elementMessages : null} className={position ? 'sender' : 'reciver'}>
                                                    <div className="content">
                                                        {el.attach_file_title ? ['jpg', 'jpeg', 'png', 'gif'].includes(el.attach_file_type.toLowerCase()) ? (
                                                            <div className="image">
                                                                {loaded ? (<div className="loader"></div>) : ''}
                                                                <a href={el.attach_file_title} target="_blank"><img src={el.attach_file_title} alt="" /></a>
                                                            </div>
                                                        ) : (
                                                            <div className="content_file">
                                                                <div className="right">
                                                                    {loaded ? (<div className="loader voice"></div>) : el.msg_type == 3 ? (
                                                                        <>
                                                                            {this.dateResult(el.result.message.voice.duration)}
                                                                        </>
                                                                    ) : el.attach_file_type}
                                                                </div>
                                                                <div className="center">
                                                                    {audio ? (
                                                                        <div className="progress_bar"></div>
                                                                    ) : (
                                                                        <div className="name">{el.attach_file ? el.attach_file.substr(0, 10) + '...' : ''}</div>
                                                                    )}
                                                                </div>
                                                                <div className="left">
                                                                    <span onClick={() => {
                                                                        if (audio && !this.state.AudioPlayRuning) {
                                                                            this.setState({ AudioPlay: el.attach_file_title })
                                                                            this.setState({ AudioPlayRuning: true })
                                                                        } else {
                                                                            this.setState({ AudioPlayRuning: false })
                                                                        }
                                                                    }} className="material-icons">{audio ? this.state.AudioPlay === el.attach_file_title && this.state.AudioPlayRuning ? 'pause' : 'play_arrow' : 'insert_drive_file'}
                                                                    </span></div>
                                                            </div>
                                                        ) : ''}
                                                        <span className="text">{el.text}</span>
                                                        <div className="clearfix">
                                                            <small>{date.toLocaleString('fa', options)}</small>
                                                            {el.alive ? !loaded ? (
                                                                <span className="material-icons seen">done</span>
                                                            ) : '' : !position ? (
                                                                <span className="material-icons seen">
                                                                    {el.read ? 'done_all' : 'done'}
                                                                </span>
                                                            ) : ''}
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <Form className="send_box" onSubmit={this.handleForm}>
                                    <Attach
                                        queryChatApi={this.queryChatApi}
                                        ImageModal={this.state.ImageModal}
                                        setImageModal={status => this.setState({ ImageModal: status })}
                                        setMessage={parametr => this.setState({ Message: { ...this.state.Message, FileMessage: parametr } })}
                                        Message={this.state.Message}
                                        variableMessage={this.variableMessage}
                                        uploadFile={this.uploadFile}
                                        CaptionFeild={this.CaptionFeild}
                                        addToChat={this.addToChat}
                                        TextMessageElement={this.TextMessageElement}
                                        TextMessageModalElement={this.TextMessageModalElement} />
                                    <input type="text" className="field" onChange={event => event.target.value ? !this.state.Message.TextMessage ? this.setState({ Message: { ...this.state.Message, TextMessage: true } }) : '' : this.setState({ Message: { ...this.state.Message, TextMessage: false } })} placeholder="یک پیام بنویسید ..." ref={this.TextMessageElement} />
                                    <div className="button_box">
                                        <button className={this.state.Record ? 'record' : ''} type={this.TextMessageElement.current && this.TextMessageElement.current.value ? 'submit' : 'button'} onClick={() => {
                                            if (this.TextMessageElement.current && !this.TextMessageElement.current.value) {
                                                if (this.state.Record) {
                                                    this.setState({ Record: false });
                                                } else {
                                                    this.setState({ Record: true });
                                                }
                                            }
                                        }} ref={this.triggerSubmit}>
                                            <ReactMic
                                                record={this.state.Record}
                                                className="sound-wave"
                                                mimeType="audio/mp3"
                                                onStop={this.onStop}
                                                strokeColor="#000000"
                                                backgroundColor="#FF4081" />
                                            {this.state.Record ? (
                                                <img src={window.location.origin + `/assets/image/stop.svg`} />
                                            ) : (
                                                <img className={this.state.Message.TextMessage ? 'rotate' : ''} src={window.location.origin + `/assets/image/${this.state.Message.TextMessage ? 'send' : 'voice'}.svg`} />
                                            )}
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        ) : <Loading />}
                    </>
                ) : <ReloadPage />}
            </>
        );
    }
}

export default PageLayout(withRouter(SingleChat));