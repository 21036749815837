import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connection, disconnected } from '../../actions/ConnectionAction';

const CheckConnection = () => {
    const login = localStorage.getItem("user_loged");
    const ConnectionReducer = useSelector(state => state.ConnectionReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        setInterval(() => {
            const ifConnected = window.navigator.onLine;
            if (ifConnected) {
                dispatch(connection());
            } else {
                dispatch(disconnected());
            }
        }, 3000);
    });

    return (
        <>
            {login ? !ConnectionReducer ? (
                <div className="CheckConnection">تلاش برای اتصال به اینترنت <img className="logo" src={window.location.origin + '/assets/image/loadingConnection.svg'} alt="" /></div>
            ) : '' : ''}
        </>
    );
}

export default CheckConnection;