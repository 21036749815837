import React, { useEffect } from 'react';
import { useState } from 'react';
import axios from "axios";
import { Link, useHistory } from 'react-router-dom';
import { apiDomain } from '../../../../classes/Config';
import { Button } from 'react-bootstrap';
import CompleteLayout from '../../../../hoc/CompleteLayout';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../../common/Loading';
import EmptyDialog from '../../../common/EmptyDialog';
import ReloadPage from '../../../common/ReloadPage';
import { useSelector } from 'react-redux';
import HandleServerError from '../../../../classes/HandleServerError';

const ListActions = () => {
    const [actionsList, setActions] = useState(null);
    const [perPage, setPerPage] = useState(10);
    const [Inbox, setInbox] = useState(0);
    const history = useHistory();
    const PersonReducer = useSelector(state => state.PersonReducer);

    const ConnectionReducer = useSelector(state => state.ConnectionReducer);

    const linkList = (tn) => {
        return {
            'user_account': 'profile',
            'pay_invoice': `order/${tn.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, '-')}/invoices`,
        }
    };

    useEffect(() => {
        getOrders();
    }, []);

    useEffect(() => {
        getInbox();
    }, [PersonReducer]);

    const getOrders = async () => {
        try {
            let formData = new FormData();
            let data = {
                'token': localStorage.getItem("user_loged").toString(),
                'page': 1,
                'per_page': parseInt(perPage),
                'device': "mobile: web",
            };
            if ((data.page = parseInt(data.page, 10)) && (data.per_page = parseInt(data.per_page, 10))) {
                Object.keys(data).map(element => {
                    formData.append(element, data[element]);
                });
                const resp = await axios.post(apiDomain + 'panel/orderssactionlist.json', formData);
                setActions(resp.data.data);
            }
        } catch (error) {
            if (error && error.response) {
                HandleServerError(history, error);
            }
        }
    }

    const getInbox = () => {
        if (PersonReducer) {
            let formData = new FormData();
            let data = {
                'token': localStorage.getItem("user_loged"),
                'device': 'mobile: web',
                'user_id': PersonReducer.user_id_connect,
                'pkg': 'express.psp.bpm.customer'
            };

            Object.keys(data).map(element => {
                formData.append(element, data[element]);
            });

            axios.post(apiDomain + 'panel/chatorderrooms.json', formData)
                .then(function (response) {
                    if (response.data.status) {
                        let sup = parseInt(localStorage.getItem("supportRead") ? 0 : 1);
                        let read = 0;
                        if (Object.keys(response.data).length) {
                            response.data.data.map(el => {
                                read += parseInt(el.unread_user)

                                if (el.order_id == 0) {
                                    sup = 0;
                                }
                            })
                        }
                        setInbox(read + sup);
                    }
                })
                .catch(function (error) {
                    if (error && error.response) {
                        HandleServerError(history, error);
                    }
                });
        }
    }

    const scrollPagination = () => {
        let scrollTop = (document.body.scrollHeight - window.innerHeight) - 200;
        if (scrollTop <= window.scrollY) {
            setPerPage(parseInt(perPage + 10));
            getOrders();
        }
    }

    return (
        <>
            { ConnectionReducer ? (
                <>
                    <Link to="/chat" className="startChatBar">
                        <img className="chat" src={window.location.origin + '/assets/image/chat.svg'} className="chat" />
                        <span>{Inbox ? Inbox + ' پیام خوانده نشده' : 'ورود به چت'}</span>
                        <div className={Inbox ? 'icons float-left new' : 'icons float-left'}>
                            <span className="material-icons">keyboard_arrow_left</span>
                            <span className="material-icons">keyboard_arrow_left</span>
                            <span className="material-icons">keyboard_arrow_left</span>
                        </div>
                    </Link>
                    <InfiniteScroll dataLength={actionsList ? Object.keys(actionsList).length : 0} onScroll={() => scrollPagination()}>
                        {actionsList ? Object.keys(actionsList).length ? (
                            <div className="page_style_list" onScroll={() => scrollPagination()}>
                                <ul className="list clearfix">
                                    {actionsList ? Object.keys(actionsList).map(element => (
                                        <li key={element}>
                                            <Link to={actionsList[element].action_name ? linkList(actionsList[element].tn)[actionsList[element].action_name] : ''}>
                                                <div className="content">
                                                    <div className="tn">{actionsList[element].tn.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, '-')}</div>
                                                    <div className="status">
                                                        <small>{actionsList[element].action_name_text}</small>
                                                        <div className="status_title">
                                                            <span>{actionsList[element].action_name_title}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Button type="submit" variant="primary" size="lg" block>
                                                    {actionsList[element].action_left}
                                                </Button>
                                            </Link>
                                        </li>
                                    )) : ''}
                                </ul>
                            </div>
                        ) : <EmptyDialog message="شما عملیاتی برای انجام ندارید" /> : <Loading />}
                    </InfiniteScroll>
                </>
            ) : <ReloadPage />}
        </>
    );
}

export default CompleteLayout(ListActions);