export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const tnFormat = (tn) => {
    return tn.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, '-')
}

export const numericFilter = (txb) => {
    const input = txb.target.value
    let cleantxt = input.replace(/[^0-9-]/i, '');
    txb.target.value = cleantxt;
}

export const ExtactNumber = (value) => {
    return value.replace(/[^0-9-]/i, '');
}