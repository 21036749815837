import React, { useState } from 'react';
import { List, ListItem, ListItemText, SwipeableDrawer } from '@material-ui/core';
import { NavLink, useHistory, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DrawerMenuToggle, DrawerMenuToggleClose } from '../../actions/DrawerMenuAction';
import OptionModal from '../pages/loginComponent/parts/OptionModal';

const Sidebar = () => {
    const [ModalOption, setModal] = useState(false);
    const DrawerMenuToggleState = useSelector(state => state.DrawerMenuReducer);
    const PersonReducer = useSelector(state => state.PersonReducer);
    const dispatch = useDispatch();
    const history = useHistory();

    const logout = () => {
        localStorage.clear();
        history.push('/login');
    }
    return (
        <>
            <OptionModal ModalOption={ModalOption} ModalHide={() => setModal(false)} />
            <React.Fragment>
                <SwipeableDrawer
                    anchor='right'
                    open={DrawerMenuToggleState}
                    onClose={() => dispatch(DrawerMenuToggleClose())}
                    onOpen={() => dispatch(DrawerMenuToggle())}
                >
                    <div className="contentSideBar">
                        <div className="profile">
                            <img src={window.location.origin + '/assets/image/user.png'} alt="" />
                            <div className="clearfix"></div>
                            <span className="name">{PersonReducer ? PersonReducer.name : ''}</span>
                            <div className="clearfix"></div>
                            <span className="mobile">{PersonReducer ? PersonReducer.mobile1 : ''}</span>
                            <hr />
                        </div>
                        <List>
                            <NavLink onClick={() => dispatch(DrawerMenuToggleClose())} to="/order/create" exact>
                                <ListItem button >
                                    {/* <ListItemIcon></ListItemIcon> */}
                                    <ListItemText primary={'ثبت سفارش جدید'} />
                                </ListItem>
                            </NavLink>
                            <NavLink onClick={() => dispatch(DrawerMenuToggleClose())} to="/profile">
                                <ListItem button >
                                    {/* <ListItemIcon></ListItemIcon> */}
                                    <ListItemText primary={'ویرایش حساب کاربری'} />
                                </ListItem>
                            </NavLink>
                            <NavLink onClick={() => dispatch(DrawerMenuToggleClose())} to="/chat">
                                <ListItem button >
                                    {/* <ListItemIcon></ListItemIcon> */}
                                    <ListItemText primary={'اعلان های کاربر'} />
                                </ListItem>
                            </NavLink>
                            <NavLink onClick={() => {
                                dispatch(DrawerMenuToggleClose())
                                setModal(true)
                            }} to="">
                                <ListItem button >
                                    {/* <ListItemIcon></ListItemIcon> */}
                                    <ListItemText primary={'رهگیری مرسوله'} />
                                </ListItem>
                            </NavLink>
                            <NavLink onClick={() => dispatch(DrawerMenuToggleClose())} to="/shipingcost">
                                <ListItem button >
                                    {/* <ListItemIcon></ListItemIcon> */}
                                    <ListItemText primary={'استعلام قیمت حمل'} />
                                </ListItem>
                            </NavLink>
                            <NavLink onClick={() => dispatch(DrawerMenuToggleClose())} to="/commingsoon">
                                <ListItem button >
                                    {/* <ListItemIcon></ListItemIcon> */}
                                    <ListItemText primary={'اطلاع رسانی و آموزش'} />
                                </ListItem>
                            </NavLink>
                            <NavLink onClick={() => dispatch(DrawerMenuToggleClose())} to="/commingsoon">
                                <ListItem button >
                                    {/* <ListItemIcon></ListItemIcon> */}
                                    <ListItemText primary={'نرخ ارز'} />
                                </ListItem>
                            </NavLink>
                            <NavLink onClick={() => dispatch(DrawerMenuToggleClose())} to="/commingsoon">
                                <ListItem button >
                                    {/* <ListItemIcon></ListItemIcon> */}
                                    <ListItemText primary={'تماس با پشتیبانی'} />
                                </ListItem>
                            </NavLink>
                            <NavLink onClick={() => dispatch(DrawerMenuToggleClose())} to="/commingsoon">
                                <ListItem button >
                                    {/* <ListItemIcon></ListItemIcon> */}
                                    <ListItemText primary={'درباره ما'} />
                                </ListItem>
                            </NavLink>
                            <NavLink onClick={() => dispatch(DrawerMenuToggleClose())} to="/commingsoon">
                                <ListItem button >
                                    {/* <ListItemIcon></ListItemIcon> */}
                                    <ListItemText primary={'تنظیمات'} />
                                </ListItem>
                            </NavLink>
                            <NavLink onClick={() => dispatch(DrawerMenuToggleClose())} to="" onClick={logout}>
                                <ListItem button >
                                    {/* <ListItemIcon></ListItemIcon> */}
                                    <ListItemText primary={'خروج از حساب کاربری'} />
                                </ListItem>
                            </NavLink>
                        </List>
                    </div>
                </SwipeableDrawer>
            </React.Fragment>
        </>
    );
}

export default withRouter(Sidebar);