import React from 'react';

const LoginHoc = (RootComponent) => {
    return props => (
        <>
            <RootComponent/>
        </>
    );
}

export default LoginHoc;