import axios from 'axios';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import CrateOrderContext from '../Context';

const EditModal = (props) => {

    const uploadFile = useRef(null);
    const ProformaInvoiceElement = useRef(null);
    const PackingListElement = useRef(null);
    const context = useContext(CrateOrderContext);
    const product = context ? context.productEditRowData.data : null;
    const productKey = context ? context.productEditRowData.key : null;
    const editDisable = context.editDisable;
    const [orderProduct, setOrderProduct] = useState({});
    const orderProductCollectionStatic = editDisable ? context.EditModalData.OrderProductCollectionStatic[orderProduct.id] : null;

    useEffect(() => {
        setOrderProduct(product);
    }, [props.data]);

    const uploadDeleteFile = (key) => {
        let imagesData = {};
        Object.keys(orderProduct.images).map(element => {
            if (element !== key) {
                imagesData[element] = orderProduct.images[element];
            }
        });
        setOrderProduct({ ...orderProduct, images: imagesData });
    }

    const uploadMultiFile = (event) => {
        let file = event.target.files[0];
        let lastModified = file.lastModified;
        let size = file.size;
        let images = orderProduct.images;
        if (file) {
            setOrderProduct({ ...orderProduct, images: { ...orderProduct.images, [lastModified + size]: file } });
        }
    }

    const ProformaInvoiceUpload = (event) => {
        let file = event.target.files[0];
        if (file) {
            setOrderProduct({ ...orderProduct, proforma_invoice: file })
        }
    }

    const PackingListUpload = (event) => {
        let file = event.target.files[0];
        if (file) {
            setOrderProduct({ ...orderProduct, packing_list: file })
        }
    }

    const orderProductAdded = (e) => {
        e.preventDefault();

        if (context.validateOrderProductFieldsCo(orderProduct)) {
            if (orderProduct.id) {
                context.GetUpdateProductsIDs(orderProduct.id);
            }
            context.handleEditProducts(orderProduct, productKey);
        }
    }

    const removeIt = (e) => {
        e.target.closest('li').remove();
    }

    return (
        <Modal centered show={context.EditModalState} onHide={context.EditModalClose} animation={false} className="customModalBg">
            <div className="noBorderModalContent listLimitModalWithSearch">
                <Form onSubmit={orderProductAdded}>
                    <div className="content">
                        <Form.Group>
                            <Form.Label>عنوان کالا</Form.Label>
                            <Form.Control className="text-center" onChange={event => setOrderProduct({ ...orderProduct, title_fa: event.target.value })} defaultValue={product ? product.title_fa : ''} placeholder='نام فارسی کالا' type="text" disabled={editDisable} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control className="text-center" onChange={event => setOrderProduct({ ...orderProduct, title_en: event.target.value })} defaultValue={product ? product.title_en : ''} placeholder='نام انگلیسی کالا' type="text" disabled={editDisable} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>تعداد</Form.Label>
                            <Form.Control className="text-center" onChange={event => setOrderProduct({ ...orderProduct, count: event.target.value })} defaultValue={product ? product.count : ''} type="number" disabled={editDisable} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>وزن هر واحد (کیلو گرم)</Form.Label>
                            <Form.Control className="text-center" onChange={event => setOrderProduct({ ...orderProduct, weight: event.target.value })} defaultValue={product ? product.weight : ''} type="number" disabled={editDisable} />
                        </Form.Group>
                        <Row className="three_cloumn">
                            <Col className="paddleft">
                                <Form.Group>
                                    <Form.Label>طول (cm)</Form.Label>
                                    <Form.Control className="text-center" onChange={event => setOrderProduct({ ...orderProduct, length: event.target.value })} defaultValue={product ? product.length : ''} type="number" />
                                </Form.Group>
                            </Col>
                            <Col className="paddleft">
                                <Form.Group>
                                    <Form.Label>عرض (cm)</Form.Label>
                                    <Form.Control className="text-center" onChange={event => setOrderProduct({ ...orderProduct, width: event.target.value })} defaultValue={product ? product.width : ''} type="number" />
                                </Form.Group>
                            </Col>
                            <Col className="paddright">
                                <Form.Group>
                                    <Form.Label>ارتفاع (cm)</Form.Label>
                                    <Form.Control className="text-center" onChange={event => setOrderProduct({ ...orderProduct, height: event.target.value })} defaultValue={product ? product.height : ''} type="number" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="paddleft">
                                <Form.Group>
                                    <Form.Label className="text-left">Proforma Invoice</Form.Label>
                                    <Button variant="secondary" onClick={event => {
                                        if (editDisable) {
                                            if (orderProductCollectionStatic.proforma_invoice) {
                                                return false;
                                            }
                                        }
                                        ProformaInvoiceElement.current.click();
                                    }
                                    } className="btn_wide">
                                        انتخاب کنید
                                            {orderProduct['proforma_invoice'] ? (<i onClick={() => {
                                            if (orderProductCollectionStatic.proforma_invoice && editDisable) {
                                                return false;
                                            }
                                            if (orderProduct.id) {
                                                context.DeleteAttach(orderProduct, 'proforma_invoice');
                                            }
                                            setOrderProduct({ ...orderProduct, proforma_invoice: null });
                                        }} className="ti-close close"></i>) : ''}
                                    </Button>
                                    <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf" hidden ref={ProformaInvoiceElement} onChange={event => {
                                        if (orderProduct && orderProduct.id) {
                                            context.EditModalData.UpdateAttachFileUpdate(event, 'proforma_invoice', orderProduct.id);
                                        }
                                        ProformaInvoiceUpload(event);
                                    }} />
                                </Form.Group>
                            </Col>
                            <Col className="paddright">
                                <Form.Group>
                                    <Form.Label className="text-left">Packing List</Form.Label>
                                    <Button variant="secondary" className="btn_wide" onClick={event => {
                                        if (editDisable) {
                                            if (orderProductCollectionStatic.packing_list) {
                                                return false;
                                            }
                                        }
                                        PackingListElement.current.click()
                                    }}>
                                        انتخاب کنید
                                            {orderProduct['packing_list'] ? (<i onClick={() => {
                                            if (orderProductCollectionStatic.packing_list && editDisable) {
                                                return false;
                                            }
                                            setOrderProduct({ ...orderProduct, packing_list: null });
                                        }
                                        } className="ti-close close"></i>) : ''}
                                    </Button>
                                    <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf" hidden ref={PackingListElement} onChange={event => {
                                        if (orderProduct && orderProduct.id) {
                                            context.EditModalData.UpdateAttachFileUpdate(event, 'packing_list', orderProduct.id);
                                        }
                                        PackingListUpload(event)
                                    }} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group>
                            <Button onClick={event => uploadFile.current.click()} variant="success" className="btn_wide">اضافه کردن تصویر کالا <span className="ti-plus icon"></span></Button>
                            <input type="file" hidden ref={uploadFile} onChange={event => uploadMultiFile(event)} accept="image/jpeg,image/gif,image/png,application/pdf" />
                            <div className="listImageUpload">
                                <ul className="clearfix">
                                    {orderProduct && orderProduct.id && Object.keys(orderProduct.images_saved).length ? (
                                        <>
                                            {orderProduct.images_saved ? Object.keys(orderProduct.images_saved).map((element, index) => (
                                                <>
                                                    {Object.keys(orderProduct.images_saved[element]).length ? (
                                                        <li key={index} onClick={(e) => {
                                                            if (!editDisable) {
                                                                context.GetDeleteImageProductsIDs(orderProduct.images_saved[element]);
                                                                removeIt(e);
                                                            }
                                                        }}>
                                                            {!editDisable ? (<i className="ti-close close"></i>) : ''}
                                                            <img src={orderProduct.images_saved[element].filename} />
                                                        </li>
                                                    ) : ''
                                                    }
                                                </>
                                            )) : ''}
                                        </>
                                    ) : ''}
                                    {Object.keys(orderProduct).length ? (
                                        <>
                                            {orderProduct.images && Object.keys(orderProduct.images).length ? Object.keys(orderProduct.images).map(element => (
                                                <>
                                                    <li key={'image' + element} onClick={() => uploadDeleteFile(element)}>
                                                        <i className="ti-close close"></i>
                                                        <img src={URL.createObjectURL(orderProduct.images[element])} />
                                                    </li>
                                                </>
                                            )) : ''}
                                        </>
                                    ) : ''}
                                </ul>
                            </div>
                        </Form.Group>
                    </div>
                    <Button type="submit" variant="primary" size="lg" block>ثبت</Button>
                </Form>
            </div>
        </Modal>
    );
}

export default EditModal;