import React, { useEffect, useState } from 'react';
import PageLayout from '../../../hoc/PageLayout';
import { Button, Form } from 'react-bootstrap';
import { seo } from '../../../classes/Seo';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { apiDomain } from '../../../classes/Config';
import { toast } from 'react-toastify';
import PageHeader from '../../common/PageHeader';
import { useHistory } from 'react-router';

const EditProfile = () => {

    const [personalData, setPersonalData] = useState({});
    const [Load, setLoad] = useState(false);
    const history = useHistory();

    const eventProdileEditHandle = (e) => {
        e.preventDefault();
        if (!Load) {
            return false;
        }

        const dataField = ['firstname', 'lastname', 'gender', 'firstname_en', 'lastname_en', 'codemelli', 'phone1', 'postalcode', 'address'];

        let data = {};
        Object.keys(e.target.elements).map(element => {
            if (dataField.includes(element)) {
                let value = e.target.elements[element].value;
                data[element] = value ? value : null;
            }
        });

        let formData = new FormData();
        formData.append('token', localStorage.getItem("user_loged"));
        formData.append('device', 'mobile: web');
        Object.keys(data).map(element => {
            formData.append(element, data[element] ? data[element] : null);
        });

        axios.post(apiDomain + 'panel/personeditinformation.json', formData)
            .then(function (response) {
                if (response.data.status) {
                    toast.success(response.data.msg);
                    history.push('/');
                } else {
                    toast.alert(response.data.msg);
                }
            }).catch(function (error) { });
    }

    useEffect(() => {
        getUserAndSet();
        seo({
            title: 'ویرایش حساب کاربری',
            backurl: window.location.hostname,
        });
    }, []);

    const getUserAndSet = () => {
        let formData = new FormData();
        formData.append('token', localStorage.getItem("user_loged"));
        formData.append('device', 'mobile: web');
        axios.post(apiDomain + "/panel/userget.json", formData)
            .then(function (response) {
                setPersonalData(response.data.person);
                setLoad(true);
            })
            .catch(function (error) { });
    }

    return (
        <>
            <PageHeader prevLink="/" titlePage="ویرایش حساب کاربری" />
            <Form onSubmit={eventProdileEditHandle}>
                <div className="page_style static form_page page_profile">
                    <div className="content">
                        <div className="row clearfix">
                            <div className="paddleft col">
                                <Form.Group>
                                    <Form.Label>نام</Form.Label>
                                    <Form.Control name='firstname' type="text" defaultValue={personalData ? personalData.firstname : null} placeholder="نام" />
                                </Form.Group>
                            </div>
                            <div className="paddright col">
                                <Form.Group>
                                    <Form.Label>نام کاربری</Form.Label>
                                    <Form.Control name='lastname' type="text" defaultValue={personalData ? personalData.lastname : null} placeholder="نام خانوادگی" />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row clearfix">
                            <div className="paddleft col">
                                <Form.Group>
                                    <Form.Label className="text-left">Lastname</Form.Label>
                                    <Form.Control name='lastname_en' type="text" defaultValue={personalData ? personalData.lastname_en : null} placeholder="Last name" />
                                </Form.Group>
                            </div>
                            <div className="paddright col">
                                <Form.Group>
                                    <Form.Label className="text-left">Firstname</Form.Label>
                                    <Form.Control name='firstname_en' type="text" defaultValue={personalData ? personalData.firstname_en : null} placeholder="First name" />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row clearfix gender">
                            <ul>
                                <li>
                                    <Form.Label>جنسیت</Form.Label>
                                </li>
                                <li>
                                    {personalData && personalData.gender === "1" ? (
                                        <Form.Check inline label="مرد" type="radio" name="gender" id="men" defaultValue="1" defaultChecked />
                                    ) :
                                        <Form.Check inline label="مرد" type="radio" name="gender" defaultValue="1" id="men" />
                                    }
                                </li>
                                <li>
                                    {personalData && personalData.gender === "2" ? (
                                        <Form.Check inline label="زن" type="radio" name="gender" defaultValue="2" id="woman" defaultChecked />
                                    ) :
                                        <Form.Check inline label="زن" type="radio" name="gender" defaultValue="2" id="woman" />
                                    }
                                </li>
                            </ul>
                        </div>
                        <div className="row">
                            <Form.Group>
                                <Form.Label>کد ملی</Form.Label>
                                <Form.Control name='codemelli' type="text" defaultValue={personalData ? personalData.codemelli : ''} placeholder="کد ملی" />
                            </Form.Group>
                        </div>
                        <div className="row">
                            <Form.Group>
                                <Form.Label>تلفن ثابت</Form.Label>
                                <Form.Control name='phone1' type="text" defaultValue={personalData ? personalData.phone1 : ''} placeholder="تلفن ثابت" />
                            </Form.Group>
                        </div>
                        <div className="row">
                            <Form.Group>
                                <Form.Label>کد پستی</Form.Label>
                                <Form.Control name='postalcode' type="text" defaultValue={personalData ? personalData.postalcode : ''} placeholder="کد پستی" />
                            </Form.Group>
                        </div>
                        <div className="row nomarg">
                            <Form.Group>
                                <Form.Label>آدرس</Form.Label>
                                <Form.Control name='address' as="textarea" defaultValue={personalData ? personalData.address : ''} rows={3} placeholder="آدرس" />
                            </Form.Group>
                        </div>
                    </div>
                    <Button type="submit" variant="primary" size="lg" block>
                        ذخیره تغییرات
                    </Button>
                </div>
            </Form>
        </>
    );
}
export default PageLayout(EditProfile);