import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const PageHeader = (props) => {
    const DrawerMenuToggleState = useSelector(state => state.DrawerMenuReducer);
    const [EditToggle, setEditToggle] = useState(false);

    return (
        <header>
            {DrawerMenuToggleState}
            <div className="title">
                {props.edit ? (
                    <div className="more">
                        <button onClick={() => setEditToggle(!EditToggle)} className="switch"><i className="ti-more-alt"></i></button>
                        {EditToggle ? (
                            <ul>
                                <li>
                                    <Link to={props.edit}>
                                        <i className="ti-pencil"></i>
                                        <span>ویرایش</span>
                                    </Link>
                                </li>
                                {props.deleteOrder ? (
                                    <li className="delete" onClick={() => {
                                        props.deleteOrder();
                                        setEditToggle(false);
                                    }}>
                                        <Link to={false}>
                                            <i className="ti-trash"></i>
                                            <span>حذف</span>
                                        </Link>
                                    </li>
                                ) : ''}
                            </ul>
                        ) : ''}
                    </div>
                ) : ''}
                {props.titlePage ? (
                    <span className={props.titleBold ? 'bold' : ''}>{props.titlePage}</span>
                ) : ''}
            </div>
            <Link to={props.prevLink ? props.prevLink : ''} className="prevLink">
                <svg width="22" height="22" viewBox="0 0 25 25" xmlns="">
                    <path d="M19,11H7.83l4.88,-4.88c0.39,-0.39 0.39,-1.03 0,-1.42 -0.39,-0.39 -1.02,-0.39 -1.41,0l-6.59,6.59c-0.39,0.39 -0.39,1.02 0,1.41l6.59,6.59c0.39,0.39 1.02,0.39 1.41,0 0.39,-0.39 0.39,-1.02 0,-1.41L7.83,13H19c0.55,0 1,-0.45 1,-1s-0.45,-1 -1,-1z" />
                </svg>
            </Link>
        </header>
    );
}

export default PageHeader;