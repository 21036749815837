import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import LoginHoc from '../../../hoc/LoginHoc';
import { useDispatch } from 'react-redux';
import { Login } from './../../../actions/LoginAction';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { UpdatePersonCurrenLogin } from '../../../actions/PersonAction';
import { apiDomain } from './../../../classes/Config';
import LoadingButtonModal from '../../common/LoadingButtonModal';
import { DrawerMenuToggleClose } from '../../../actions/DrawerMenuAction';
import OptionModal from './parts/OptionModal';
import CountryModal from './parts/CountryModal';
import PhoneInput from 'react-phone-number-input'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import { ExtactNumber } from './../../../classes/utility';
import en from 'react-phone-number-input/locale/en.json'

const formUrlEncoded = x =>
    Object.keys(x).reduce((p, c) => p + `&${c}=${encodeURIComponent(x[c])}`, '')

const FormEnterMobile = () => {
    const counterSec = 60;
    const form = useRef(null);
    const [mobile, setMobile] = useState('');
    const [codeSms, setCodeSms] = useState(false);
    const [stepAuth, setStepAuth] = useState(false);
    const [verifytoken, setVerifyToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [counter, setCounter] = useState(counterSec);
    const [OptionClosed, setOptionClosed] = useState(false);
    const [ModalOption, setModal] = useState(false);
    const [CountryList, setCountryList] = useState(null);
    const [ModalCountryState, setModalCountryState] = useState(false);
    const [CountrySelected, setCountrySelected] = useState({
        'title': 'IR',
        'code': 98,
        'flag': `https://purecatamphetamine.github.io/country-flag-icons/3x2/IR.svg`
    })
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        getCountriesFunc();
        localStorage.clear();
    }, []);

    useEffect(() => {
        if (stepAuth && counter > 0) {
            startCountDown();
        }
    });

    const getCountriesFunc = () => {
        let country = {}
        getCountries().map(e => {
            country[e] = {
                'title': e,
                'label': en[e],
                'code': getCountryCallingCode(e),
                'flag': `https://purecatamphetamine.github.io/country-flag-icons/3x2/${e}.svg`,
            }
            setCountryList(country);
        })
    }

    const modalCountryHandle = () => {
        setModalCountryState(!ModalCountryState)
    }

    const startCountDown = () => {
        const coundown = setInterval(() => {
            clearInterval(coundown);
            if (counter > 0) {
                setCounter(counter - 1);
            } else {
                setCounter(counterSec);
            }
        }, 1000);
    }

    const SelectCode = (country) => {
        if (!country) {
            return false
        }
        setCountrySelected({
            'title': country.title,
            'code': country.code,
            'flag': country.flag
        })
    }

    function eventLoginHandle(e) {
        e.preventDefault();
        setLoading(true);

        if (!stepAuth) {
            axios.post(apiDomain + 'auth/login.json', formUrlEncoded({
                mobile: CountrySelected.code + mobile,
                device: 'mobile: web',
                pkg: 'express.psp.bpm.customer',
            }))
                .then(function (response) {
                    if (response.data.status) {
                        setVerifyToken(response.data.verifytoken);
                        setStepAuth(true);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        toast.error(response.data.msg);
                    }
                })
                .catch(function (error) {
                    setLoading(false);
                    alert('خطا در برقراری با سرور');
                });
        } else {
            axios.post(apiDomain + 'auth/loginbycode.json', formUrlEncoded({
                verifytoken: verifytoken,
                code: codeSms,
                mobile: CountrySelected.code + mobile,
                device: 'mobile: web',
                pkg: 'express.psp.bpm.customer',
            }))
                .then(function (response) {
                    if (response.data.user && response.data.status) {
                        dispatch(Login(response.data.user.crypttoken));
                        dispatch(UpdatePersonCurrenLogin(response.data.person));
                        dispatch(DrawerMenuToggleClose());
                        history.push("/");
                    } else {
                        setLoading(false);
                        toast.error(response.data.msg);
                    }
                })
                .catch(function (error) {
                    // console.log(error);
                });
        }
    }
    return (
        <>
            <header>
                <div className="title">ورود به برنامه</div>
                <img className="logo" src={window.location.origin + '/assets/image/logo.png'} alt="" />
            </header>
            <CountryModal ModalStatus={ModalCountryState} ModalHandle={modalCountryHandle} CountryList={CountryList} SelectCode={SelectCode} />
            <OptionModal ModalOption={ModalOption} ModalHide={() => setModal(false)} />
            <Form onSubmit={eventLoginHandle} ref={form}>
                <div className="page_style login_page form_page">
                    <div className="content">
                        {stepAuth ? (
                            <>
                                <div className="chnagephone" onClick={() => {
                                    setStepAuth(false);
                                    setCounter(counterSec);
                                    for (let i = 0; i < 100; i++) {
                                        window.clearInterval(i);
                                    }
                                }}>
                                    <svg width="15" height="15" viewBox="0 0 25 25" xmlns="">
                                        <path d="M19,11H7.83l4.88,-4.88c0.39,-0.39 0.39,-1.03 0,-1.42 -0.39,-0.39 -1.02,-0.39 -1.41,0l-6.59,6.59c-0.39,0.39 -0.39,1.02 0,1.41l6.59,6.59c0.39,0.39 1.02,0.39 1.41,0 0.39,-0.39 0.39,-1.02 0,-1.41L7.83,13H19c0.55,0 1,-0.45 1,-1s-0.45,-1 -1,-1z" />
                                    </svg>
                                    تغییر شماره
                                </div>
                                <div className="text">
                                    <p>کد برای شماره {`${CountrySelected.code}${ExtactNumber(mobile)}`} ارسال شد</p>
                                    <small>لطفا کد دریافتی را در کادر ذیل وارد کنید</small>
                                </div>
                                <Form.Group>
                                    <Form.Control onChange={e => setCodeSms(e.target.value)} key='codesms' type="number" name="codesms" placeholder="کد ارسال شده را وارد نمائید" />
                                </Form.Group>
                                <div className="resend" onClick={() => counter == 0 ? setCounter(counterSec) && eventLoginHandle() : ''}>
                                    {counter ? (
                                        <>
                                            <span className="counter">00:{counter}</span>
                                            تا {' '}
                                        </>
                                    ) : ''}
                                    ارسال مجدد کد
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="text">
                                    <p>شماره تلفن همراه خود را وارد نمایید</p>
                                    <small>کد ورود برای شما ارسال میشود</small>
                                </div>
                                <Form.Group>
                                    {/* <PhoneInput
                                        defaultCountry="IR"
                                        international
                                        className="form-control"
                                        value={mobile}
                                        onChange={setMobile} /> */}
                                    <div className="feild_EnterMobile">
                                        <Form.Control onChange={e => setMobile(e.target.value)} key='mobile' type="number" name="mobile" placeholder="091..." />
                                        <div className="detail" onClick={() => setModalCountryState(true)}>
                                            <img src={CountrySelected.flag} alt="" />
                                            <span>{`(${CountrySelected.title}) +${CountrySelected.code}`}</span>
                                        </div>
                                    </div>
                                    {/* <NumberFormat name="mobile" key='mobile' onChange={e => setMobile(e.target.value)} placeholder="091..." className="form-control" format="###########" /> */}
                                </Form.Group>
                            </>
                        )}
                    </div>
                    <Button type="submit" variant="primary" size="lg" block>
                        ورود به برنامه
                        <LoadingButtonModal show={loading} />
                    </Button>
                </div>
            </Form>

            <div className={OptionClosed ? 'optionList closed' : 'optionList'}>
                <button className="switch"><i className="ti-close" onClick={() => setOptionClosed(!OptionClosed)}></i></button>
                <ul>
                    <li className="tracking clearfix" onClick={() => setModal(true)}>
                        <button><img src={window.location.origin + '/assets/image/tracking.svg'} alt="" /></button>
                        <span>رهگیری سفارش</span>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default LoginHoc(FormEnterMobile);