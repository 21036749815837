import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { apiDomain } from '../../../../../classes/Config';
import HandleServerError from '../../../../../classes/HandleServerError';

const HistoryTransactions = () => {

    const [showTransactions, setShowTransactions] = useState(false);
    const [dataTransactions, setDataTransactions] = useState(null);
    const history = useHistory();

    useEffect(() => {
        getTransactions();
    }, []);


    const getTransactions = () => {
        let formData = new FormData();
        let data = {
            'token': localStorage.getItem("user_loged").toString(),
            'page': 1,
            'per_page': 40,
            'device': "mobile: web",
        };
        Object.keys(data).map(element => {
            formData.append(element, data[element]);
        });
        axios.post(apiDomain + 'panel/transactionslist.json', formData)
            .then(function (response) {
                if (response.data.status) {
                    setDataTransactions(response.data.data);
                }
            })
            .catch(function (error) {
                if (error && error.response) {
                    HandleServerError(history, error);
                }
            });
    }

    return (
        <>
            <div className="chargeButton buttonStyle">
                <div className="cols" onClick={() => setShowTransactions(!showTransactions)}>
                    <div className="labels">سابقه تراکنش ها</div>
                    <div className="whiteBoxFix"><i className={showTransactions ? 'fa fa-arrow-up' : 'fa fa-arrow-down'} aria-hidden="true"></i></div>
                </div>
            </div>

            {showTransactions ? (
                <ul className="transactions clearfix">
                    {dataTransactions ? dataTransactions.map(element => {
                        let price = element.rial ? element.rial : element.yuan;
                        return (
                            <li className="buttonStyle">
                                <div className="detail float-right">
                                    <div>{element.title}</div>
                                    <div>{element.time_created_title}</div>
                                </div>
                                <div className="whiteBoxFix float-left">
                                    <div className="centerBox">
                                        <div className={price >= 0 ? 'price green' : 'price red'}>{price ? price : 0}</div>
                                        <div className="unit">{price ? element.rial ? 'ریال' : 'یوان' : 0}</div>
                                    </div>
                                </div>
                            </li>
                        )
                    }) : ''}
                </ul>
            ) : ''}
        </>
    );
}

export default HistoryTransactions;