const HandleServerError = (history, error) => {
    if (error && error.response) {
        if (error.response.status == 401) {
            history.replace('/reload');
            setTimeout(() => {
                history.push('/login');
            });
        }
    }
}

export default HandleServerError;