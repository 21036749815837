import React from 'react';

const LoadingButtonModal = (props) => {
    return (
        <>
            {props.show ? (
                <div className="loading_button"></div>
            ) : ''}
        </>
    );
}
export default LoadingButtonModal;