import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { apiDomain } from '../../../../../classes/Config';
import { numberWithCommas } from '../../../../../classes/utility';


const TransActions = (props) => {
    const invoice = props.invoice;
    const [TransActions, setTransActions] = useState(null);

    useEffect(() => {
        if (invoice) {
            let formData = new FormData();
            formData.append('token', localStorage.getItem("user_loged"));
            formData.append('page', 1);
            formData.append('per_page', 100);
            formData.append('order_id', invoice.order_tn_title);
            formData.append('device', "mobile: web");

            axios.post(apiDomain + 'panel/invoicepaymentlist.json', formData)
                .then(function (response) {
                    setTransActions(response.data.data);
                })
                .catch(function (error) {
                    // console.log(error);
                });
        }
    }, [props.invoice]);

    return (
        <>
            <Modal centered show={props.modalState} onHide={props.handleModal} animation={false} className="customModalBg TransActionsList">
                <div className="noBorderModalContent invoicesPaymentModal">
                    <div className="content">
                        <ul>
                            {TransActions ? TransActions.map(element => (
                                <li>
                                    <div className="right">
                                        <span className="title">{element.payment_type_title}</span>
                                        <span className="date">{element.time_created_title}</span>
                                    </div>
                                    <div className="left">
                                        <span className="amount">{numberWithCommas(element.amount)}</span>
                                        <br />
                                        <small>{element.priceunit_title}</small>
                                    </div>
                                </li>
                            )) : ''}
                        </ul>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default TransActions;