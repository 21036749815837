import React from 'react';
import { Modal } from 'react-bootstrap';

const CountryModal = (props) => {
    const data = props.CountryModalData;
    const showCountryModalShow = data.showCountryModalShow;
    const handleCountryModalClose = data.handleCountryModalClose;
    const searchCountry = data.searchCountry;
    const country = data.country;
    const handleStartingCity = data.handleStartingCity;
    
    return (
        <Modal centered show={showCountryModalShow} onHide={handleCountryModalClose} animation={false}>
            <div className="noBorderModalContent listLimitModalWithSearch">
                <div className="content">
                    <div className="field">
                        <input type="text" onKeyUp={(event) => searchCountry(event.target.value)} placeholder="نام کشور را بنویسید" />
                    </div>
                    <ul>
                        {country ? country.map(element => (
                            <>
                                <li key={'costart' + element} onClick={() => handleStartingCity(element.key, element.value)} key={element.key}>{element.value}</li>
                            </>
                        )) : ''}
                        <li></li>
                    </ul>
                </div>
            </div>
        </Modal>
    );
}

export default CountryModal;