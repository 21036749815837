import React, { Component } from 'react';

class Loading extends Component {
    render() {
        return (
            <>
                <div className="lds-ripple"><div></div><div></div></div>
            </>
        );
    }
}

export default Loading;