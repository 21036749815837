import React from 'react';
import { Link, withRouter } from 'react-router-dom';

const Buttons = (props) => {
    const loged = localStorage.getItem("user_loged");
    return (
        <>
            <ul className="clearfix buttons">
                <li>
                    <Link to={loged ? '/order/' + props.match.params.tn + '/orderproducts' : props.to}>
                        <div className="title">کالاهای سفارش</div>
                        <div className="icon">
                            <svg className="iconsvg pack" width="36" height="36" viewBox="0 0 105 105" xmlns="">
                                <path fill="#FCD462" d="M204.798,140.256l-204.798,-70.353l0,268.918l204.8,70.779z" />
                                <path fill="#E5B053" d="M204.798,140.256l204.802,-70.353l0,268.918l-204.8,70.779z" />
                                <path fill="#F6C358" d="M204.798,0l-204.798,70.128l204.798,70.128l204.802,-70.128z" />
                                <path fill="#CE7A41" d="M281.745,26.349l-204.797,70.128l50.912,17.43l204.792,-70.126z" />
                                <path fill="#DC8744" d="M127.857,113.931l0,65.239l-30.832,-13.554l-20.108,-8.863l0,-60.248l20.108,6.851zz" />
                            </svg>
                        </div>
                        <a className="view">مشاهده</a>
                    </Link>
                </li>
                <li>
                    <Link to={loged ? '/order/' + props.match.params.tn + '/tracking' : props.to}>
                        <div className="title">وضعیت سفارش</div>
                        <div className="icon">
                            <svg className="iconsvg" width="40" height="40" viewBox="0 0 105 105" xmlns="">
                                <path fill="#d1d4d1" d="m487.457,450.227h-315.5c-7.023,-0.035 -13.383,4.137 -16.152,10.59l-22.336,51.203h-41.93l33.105,-75.918c5.629,-12.871 18.348,-21.188 32.395,-21.188h315.586zM487.457,450.227" />
                                <path fill="#71c285" d="m124.645,436.102 l-33.105,75.918h-52.082c-8.656,-0.012 -16.762,-4.254 -21.707,-11.363 -4.941,-7.105 -6.102,-16.18 -3.098,-24.301l19.418,-52.613h34.516c7.43,-0.023 14.055,-4.676 16.598,-11.652l24.629,-67.797h286.016l7.68,23.219c2.418,7.211 9.164,12.074 16.77,12.094h37.43l14.918,35.309h-315.586c-14.047,0 -26.766,8.316 -32.395,21.188zM124.645,436.102" />
                                <path fill="#71c285" d="m473.598,512.02h-340.129l22.336,-51.203c2.77,-6.453 9.129,-10.625 16.152,-10.59h315.5l10.594,25.07c3.43,8.18 2.535,17.531 -2.379,24.914 -4.918,7.387 -13.203,11.816 -22.074,11.809zM473.598,512.02" />
                                <path fill="#fdd7ad" d="m109.813,344.293 l-24.629,67.797c-2.543,6.977 -9.168,11.629 -16.598,11.652h-34.516l22.863,-62.145c3.805,-10.414 13.723,-17.332 24.809,-17.305zM109.813,344.293" />
                                <path fill="#fdd7ad" d="m457.707,379.605h-37.43c-7.605,-0.02 -14.352,-4.883 -16.77,-12.094l-7.68,-23.219h29.484c10.656,-0.012 20.277,6.398 24.363,16.242zM457.707,379.605" />
                                <path fill="#e64c3c" d="m247.539,0.316c-64.844,4.063 -120.684,47.191 -141,108.906 -20.316,61.715 -1.012,129.578 48.746,171.363 4.523,3.691 8.238,8.273 10.918,13.461l49.48,99.02c7.906,15.852 24.102,25.867 41.816,25.867s33.906,-10.016 41.816,-25.867l49.855,-99.754c2.422,-4.703 5.793,-8.852 9.906,-12.18 52.914,-43.949 71.543,-116.961 46.148,-180.891 -25.391,-63.926 -89.035,-104.258 -157.688,-99.926zM247.539,0.316" />
                                <path fill="#c03a2b" d="m389.91,158.914c0,73.129 -59.281,132.414 -132.41,132.414 -73.133,0 -132.414,-59.285 -132.414,-132.414 0,-73.129 59.281,-132.414 132.414,-132.414 73.129,0 132.41,59.285 132.41,132.414zM389.91,158.914" />
                                <path fill="#e6e7e8" d="m373.059,158.914c0,63.824 -51.738,115.563 -115.559,115.563 -63.824,0 -115.563,-51.738 -115.563,-115.563 0,-63.824 51.738,-115.563 115.563,-115.563 63.82,0 115.559,51.738 115.559,115.563zM373.059,158.914" />
                                <path fill="#fdd7ad" d="m322.469,122.809 l-64.969,28.867 -64.973,-28.867 62.059,-27.629c1.859,-0.797 3.965,-0.797 5.824,0zM322.469,122.809" />
                                <path fill="#cb8252" d="m322.469,122.809v67.531c0,2.871 -1.699,5.465 -4.324,6.621l-57.734,25.688c-0.926,0.375 -1.914,0.582 -2.91,0.617v-71.59zM322.469,122.809" />
                                <path fill="#805333" d="m257.5,151.676v71.59c-1,-0.035 -1.988,-0.242 -2.914,-0.617l-57.82,-25.688c-2.582,-1.191 -4.234,-3.777 -4.238,-6.621v-67.531zM257.5,151.676" />
                            </svg>
                        </div>
                        <a className="view">رهگیری مرسوله</a>
                    </Link>
                </li>
                <li>
                    <Link to={loged ? '/order/' + props.match.params.tn + '/invoices' : props.to}>
                        <div className="title">فاکتور های سفارش</div>
                        <div className="icon">
                            <svg className="files" width="40" height="40" viewBox="0 0 105 105" xmlns="">
                                <path fill="#ffcd00" d="m18,9h26l8,8v38h6v-52h-40z" />
                                <path fill="#ebbf00" d="m30,55h28v-25c0,13.818 -12.523,25 -28,25z" />
                                <path fill="#ffeb99" d="m52,17v44h-40v-52h32v8z" />
                                <path fill="#b38b63" d="m20,15h16v14h-16z" />
                                <path fill="#ff5023" d="m52,17 l-8,-8v8z" />
                                <path fill="#ff9811" d="m28,20 l2,1v-6h-4v6z" />
                                <path fill="#a97c50" d="m35,15v8a4,4 0,0 1,-4 4h-11v2h16v-14z" />
                                <path fill="#7ed63e" d="M36,29m-5,0a5,5 0,1 1,10 0a5,5 0,1 1,-10 0" />
                                <path fill="#ffe477" d="m24,61h28v-25c0,13.818 -12.523,25 -28,25z" />
                                <path fill="#f1f2f2" d="m37.293,27.293 l-2.293,2.293 -0.707,-0.707a1,1 0,1 0,-1.414 1.414l1.414,1.414a1,1 0,0 0,1.414 0l3,-3a1,1 0,0 0,-1.414 -1.414z" />
                                <path fill="#ffcd00" d="m20,42h-2a1,1 0,0 1,0 -2h2a1,1 0,0 1,0 2z" />
                                <path fill="#ffcd00" d="m46,42h-22a1,1 0,0 1,0 -2h22a1,1 0,0 1,0 2z" />
                                <path fill="#ffcd00" d="m46,46h-28a1,1 0,0 1,0 -2h28a1,1 0,0 1,0 2z" />
                                <path fill="#ffcd00" d="m46,50h-28a1,1 0,0 1,0 -2h28a1,1 0,0 1,0 2z" />
                                <path fill="#ffcd00" d="m46,54h-2a1,1 0,0 1,0 -2h2a1,1 0,0 1,0 2z" />
                                <path fill="#ffcd00" d="m40,54h-22a1,1 0,0 1,0 -2h22a1,1 0,0 1,0 2z" />
                            </svg>
                        </div>
                        <a className="view">نمایش فاکتور ها</a>
                    </Link>
                </li>
            </ul>
        </>
    );
}

export default withRouter(Buttons);