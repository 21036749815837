import React, { useEffect } from 'react';
import { useState } from 'react';
import axios from "axios";
import { apiDomain } from '../../../../classes/Config';
import Loading from '../../../common/Loading';
import { Link, useHistory } from 'react-router-dom';
import PageLayout from '../../../../hoc/PageLayout';
import PageHeader from '../../../common/PageHeader';
import { seo } from '../../../../classes/Seo';
import HandleServerError from '../../../../classes/HandleServerError';
import { useSelector } from 'react-redux';

const ListChats = () => {
    const [Chats, setChats] = useState(null);
    const PersonReducer = useSelector(state => state.PersonReducer);
    const history = useHistory();

    useEffect(() => {
        seo({
            title: 'لیست گفتگو ها'
        });
    }, []);

    useEffect(() => {
        getChats();
        return () => {
            setChats(null)
        };
    }, [PersonReducer]);

    const getChats = () => {
        if (PersonReducer) {
            let formData = new FormData();
            let data = {
                'token': localStorage.getItem("user_loged"),
                'device': 'mobile: web',
                'user_id': PersonReducer.user_id_connect,
                'pkg': 'express.psp.bpm.customer'
            };

            Object.keys(data).map(element => {
                formData.append(element, data[element]);
            });

            axios.post(apiDomain + 'panel/chatorderrooms.json', formData)
                .then(function (response) {
                    if (response.data.status) {
                        const emptySupport = {
                            last_message: "سلام, چطور میتونم کمکتون کنم ؟",
                            last_message_date: new Date().getTime() / 1000,
                            order_id: "0",
                            order_tn_title: "پشتیبانی",
                            unread_user: localStorage.getItem("supportRead") ? 0 : "1",
                        };
                        // console.log(response.data.data);
                        let data = response.data.data;
                        if (Object.keys(data).length) {
                            response.data.data.map(el => {
                                if (el.order_id != 0) {
                                    data['sup'] = emptySupport;
                                }
                            })
                        } else {
                            data[0] = emptySupport;
                        }

                        setChats(data);
                    }
                })
                .catch(function (error) {
                    if (error && error.response) {
                        HandleServerError(history, error);
                    }
                });
        }
    }

    return (
        <>
            <PageHeader prevLink="/actions" titlePage={'لیست گفتگو ها'} />
            {Chats ? (
                <div className="page_style_list chat--page">
                    <ul className="ListMain">
                        {Chats.map((element, key) => {
                            let now = new Date();
                            let day = 60 * 60 * 24 * 1000;
                            let calculateDate = (day) => new Date(now.getTime() - day).getTime();
                            let date = new Date(parseInt(element.last_message_date) * 1000);
                            let last = date.getTime();
                            let options = {};

                            if (calculateDate(day) > last) {
                                options = {
                                    month: "short",
                                    day: "numeric"
                                };
                            } else if (calculateDate(day * 30) < last) {
                                options = {
                                    hour: "numeric",
                                    minute: "2-digit",
                                };
                            } else {
                                options = {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "numeric"
                                };
                            }

                            let createdTime = date.toLocaleString('fa', options);
                            return (
                                <Link key={key} to={`/chat/${element.order_tn_title}`}>
                                    <li>
                                        <div className="icon">
                                            <img className="logo" src={window.location.origin + `/assets/image/${element.order_id != 0 ? 'box2' : 'avatar'}.svg`} alt="" />
                                        </div>
                                        <div className="detail">
                                            {element.order_tn_title}
                                            {parseInt(element.unread_user) > 0 ? (
                                                <span className="unread">{element.unread_user}</span>
                                            ) : ''}
                                            <br />
                                            <small><i className="ti-check"></i> {element.last_message}</small>
                                            <span className="date">{createdTime}</span>
                                        </div>
                                    </li>
                                </Link>
                            )
                        })}
                    </ul>
                </div>
            ) : <Loading />}
        </>
    );
}

export default PageLayout(ListChats);