import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { apiDomain } from '../../../../../classes/Config';
import LoadingButtonModal from '../../../../common/LoadingButtonModal';


const PaymentModal = (props) => {
    const invoice = props.invoice;
    const [statusPaymentModal, setStatusPaymentModal] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [statusPaymentMessage, setStatusPaymentModalMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const payment = (value) => {
        setLoading(true);
        let formData = new FormData();
        formData.append('token', localStorage.getItem("user_loged"));
        formData.append('invoice_id', invoice.id);
        formData.append('unit', value);
        formData.append('device', "mobile: web");

        axios.post(apiDomain + 'panel/invoicepayinvoicefrombalance.json', formData)
            .then(function (response) {
                setLoading(false);
                props.handleModal();
                setStatusPaymentModal(true);
                setStatusPaymentModalMessage(response.data.msg);
                props.getInvoices();
            })
            .catch(function (error) {
                // console.log(error);
            });
    }
    return (
        <>
            <Modal centered show={statusPaymentModal} onHide={() => setStatusPaymentModal(false)} animation={false} className="customModalBg">
                <div className="noBorderModalContent invoicesPaymentModal">
                    <div className="content">
                        <p>
                            {statusPaymentMessage}
                        </p>
                    </div>
                    <div className="buttonBlock">
                        <Button variant="primary" onClick={() => setStatusPaymentModal(false)} size="lg" block>خب</Button>
                    </div>
                </div>
            </Modal>
            {invoice && invoice.pay_btn ? (
                <>
                    <Modal centered show={props.modalState} onHide={props.handleModal} animation={false} className="customModalBg">
                        <div className="noBorderModalContent invoicesPaymentModal">
                            <div className="content">
                                <p>
                                    {console.log(invoice)}
                                    {invoice ? invoice.pay_btn.message : ''}
                                </p>
                            </div>
                            <div className="buttonBlock">
                                {invoice ? invoice.pay_btn.btn.map(element => (
                                    <>
                                        <Button variant="primary" onClick={() => payment(element.value)} size="lg" block>{element.name}</Button>
                                    </>
                                )) : ''}
                                <LoadingButtonModal show={loading} />
                            </div>
                        </div>
                    </Modal>
                </>
            ) : ''}
        </>
    );
}

export default PaymentModal;