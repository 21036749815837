import React, { useEffect } from 'react';
import { useState } from 'react';
import axios from "axios";
import { Link, useHistory, withRouter } from 'react-router-dom';
import { apiDomain } from '../../../../classes/Config';
import PageLayout from '../../../../hoc/PageLayout';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../../common/Loading';
import EmptyDialog from '../../../common/EmptyDialog';
import PaymentModal from './Part/PaymentModal';
import { Button } from 'react-bootstrap';
import PageHeader from '../../../common/PageHeader';
import { numberWithCommas } from '../../../../classes/utility';
import { useSelector } from 'react-redux';
import ReloadPage from '../../../common/ReloadPage';
import HandleServerError from '../../../../classes/HandleServerError';
import PaymentRialModalComponent from './Part/PaymentRialModalComponent';
import TransActions from './Part/TransActions';

const Invoices = (props) => {
    const [invoiceList, setInvoices] = useState(null);
    const [perPage, setPerPage] = useState(10);
    const [paymentModal, setPaymentModal] = useState(false);
    const [PaymentRialModal, setPaymentRialModal] = useState(false);
    const [invoice, setInvoice] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [imagePreviewClicked, setImagePreviewClicked] = useState(false);
    const [pdfDownloadLink, setPdfDownloadLink] = useState(null);
    const [Connection, setConnection] = useState(true);
    const [TransActionsModal, setTransActionsModal] = useState(false);
    const history = useHistory();
    const ConnectionReducer = useSelector(state => state.ConnectionReducer);

    useEffect(() => {
        if (!invoiceList) {
            getInvoices();
        }
    }, []);

    useEffect(() => {
        window.onpopstate = e => {
            if (imagePreviewClicked) {
                if (imagePreview) {
                    history.push(`/order/${props.match.params.tn}/invoices`);
                    setImagePreview(null);
                } else {
                    history.push(`/order/${props.match.params.tn}`);
                }
            }
        }
    });

    const getInvoices = async () => {
        try {
            let formData = new FormData();
            let data = {
                'token': localStorage.getItem("user_loged").toString(),
                'page': 1,
                'per_page': parseInt(perPage),
                'order_id': props.match.params.tn.match(/\d/g).join(""),
                'device': "mobile: web",
                'pkg': 'express.psp.bpm.customer'
            };
            if ((data.page = parseInt(data.page, 10)) && (data.per_page = parseInt(data.per_page, 10))) {
                Object.keys(data).map(element => {
                    formData.append(element, data[element]);
                });
                const resp = await axios.post(apiDomain + 'panel/ordersinvoicelist.json', formData);
                setInvoices(resp.data.data);
                setConnection(true);
            }
        } catch (error) {
            if (error && error.response) {
                HandleServerError(history, error);
            } else {
                setConnection(false);
            }
        }
    }

    const scrollPagination = () => {
        let scrollTop = (document.body.scrollHeight - window.innerHeight) - 200;
        if (scrollTop <= window.scrollY) {
            setPerPage(parseInt(perPage + 10));
            getInvoices();
        }
    }

    const PaymentModalClose = () => {
        setPaymentModal(false);
    }

    const download = () => {
        if (pdfDownloadLink) {
            window.open(pdfDownloadLink);
        }
    };

    return (
        <>
            <PageHeader prevLink={'/order/' + props.match.params.tn} titlePage={props.match.params.tn + ' - فاکتور های سفارش'} />
            {ConnectionReducer && Connection ? (
                <>
                    {imagePreview ? (
                        <div className="pereviewImage">
                            <Button onClick={() => setImagePreview(null)} className="back">
                                <svg width="25" height="25" viewBox="0 0 25 25" xmlns="">
                                    <path d="M19,11H7.83l4.88,-4.88c0.39,-0.39 0.39,-1.03 0,-1.42 -0.39,-0.39 -1.02,-0.39 -1.41,0l-6.59,6.59c-0.39,0.39 -0.39,1.02 0,1.41l6.59,6.59c0.39,0.39 1.02,0.39 1.41,0 0.39,-0.39 0.39,-1.02 0,-1.41L7.83,13H19c0.55,0 1,-0.45 1,-1s-0.45,-1 -1,-1z" />
                                </svg>
                            </Button>
                            <div className="image">
                                <img onClick={() => setImagePreview(null)} src={imagePreview.replace('h=200', 'h=1000')} />
                            </div>
                            <div className="buttons">
                                <ul>
                                    <li><Button onClick={() => download()}>دانلود فایل PDF</Button></li>
                                    <li><a href={imagePreview.replace('h=200', 'h=1000')} target="_blank"><Button>دانلود تصویر</Button></a></li>
                                </ul>
                            </div>
                        </div>
                    ) : ''}
                    <PaymentModal invoice={invoice} modalState={paymentModal} handleModal={() => PaymentModalClose()} getInvoices={() => getInvoices()} />
                    <TransActions invoice={invoice} modalState={TransActionsModal} handleModal={() => setTransActionsModal(false)} />
                    <PaymentRialModalComponent invoice={invoice} modalState={PaymentRialModal} handleModal={() => setPaymentRialModal(false)} getInvoices={() => getInvoices()} />
                    <InfiniteScroll className="page_order_products" dataLength={invoiceList ? Object.keys(invoiceList).length : 0} onScroll={() => scrollPagination()}>
                        {invoiceList ? Object.keys(invoiceList).length ? (
                            <div onScroll={() => scrollPagination()}>
                                <ul className="list clearfix">
                                    {invoiceList ? Object.keys(invoiceList).map(element => (
                                        <li key={element} className="clearfix">
                                            <div className="cover float-right" onClick={() => {
                                                setImagePreview(invoiceList[element].image);
                                                setPdfDownloadLink(invoiceList[element].pdf);
                                                setImagePreviewClicked(true);
                                            }}>
                                                <img src={invoiceList[element].image ? invoiceList[element].image : ''} />
                                            </div>
                                            <div className="detail float-left">
                                                <div className="title">{invoiceList[element].inquery_type_title}</div>
                                                <div className="thumb_detail">
                                                    <div className="col">شماره فاکتور {invoiceList[element].id}</div>
                                                    <div className="col">مبلغ {numberWithCommas(invoiceList[element].total_price) + ' ' + invoiceList[element].priceunit_title}</div>
                                                    <div className="col">تاریخ {invoiceList[element].time_created_title}</div>
                                                </div>
                                                <a onClick={() => {
                                                    setInvoice(invoiceList[element]);
                                                    if(invoiceList[element].is_paid){
                                                        setTransActionsModal(true);
                                                    } else {
                                                        if(invoiceList[element].priceunit == 'rial'){
                                                            setPaymentRialModal(true);
                                                        } else {
                                                            setPaymentModal(true);
                                                        }
                                                    }
                                                }}>
                                                    {invoiceList[element] && invoiceList[element].is_paid ? 'سابقه پرداخت' : 'پرداخت'}
                                                </a>
                                            </div>
                                        </li>
                                    )) : ''}
                                </ul>
                            </div>
                        ) : <EmptyDialog message="تاکنون فاکتوری برای این سفارش صادر نشده است" /> : <Loading />}
                    </InfiniteScroll>
                </>
            ) : <ReloadPage />}
        </>
    );
}

export default PageLayout(withRouter(Invoices));