import React, { useEffect } from 'react';
import { useState } from 'react';
import axios from "axios";
import { Link, useHistory } from 'react-router-dom';
import { apiDomain } from '../../../classes/Config';
import { Button } from 'react-bootstrap';
import CompleteLayout from '../../../hoc/CompleteLayout';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../common/Loading';
import EmptyDialog from '../../common/EmptyDialog';
import { tnFormat } from '../../../classes/utility';
import { useSelector } from 'react-redux';
import ReloadPage from '../../common/ReloadPage';
import HandleServerError from '../../../classes/HandleServerError';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const ListOrders = () => {
    const [ordersList, setOrders] = useState(null);
    const [perPage, setPerPage] = useState(90);
    const [Connection, setConnection] = useState(true);
    const [searcbox, setSearcbox] = useState(false);
    const [ListStatus, setListStatus] = useState(null);
    const [Filters, setFilters] = useState({
        'query': '',
        'order_status': '',
    });
    const ConnectionReducer = useSelector(state => state.ConnectionReducer);
    const history = useHistory();

    useEffect(() => {
        getOrders();
        getButtonsStatus();

        return () => {
            setOrders(null);
        }
    }, []);

    useEffect(() => {
        getOrders();
    }, [Filters]);

    const getOrders = async () => {
        if (Connection) {
            try {
                let formData = new FormData();
                let data = {
                    'token': localStorage.getItem("user_loged").toString(),
                    'order_status': Filters.order_status,
                    'query': Filters.query,
                    'page': 1,
                    'per_page': parseInt(perPage),
                    'device': "mobile: web",
                };
                if ((data.page = parseInt(data.page, 10)) && (data.per_page = parseInt(data.per_page, 10))) {
                    Object.keys(data).map(element => {
                        formData.append(element, data[element]);
                    });
                    const resp = await axios.post(apiDomain + 'panel/orderlist.json', formData);
                    setOrders(resp.data.data);
                }
            } catch (error) {
                if (error && error.response) {
                    HandleServerError(history, error);
                } else {
                    setConnection(false);
                }
            }
        }
    }

    const getButtonsStatus = async () => {
        if (Connection) {
            try {
                let formData = new FormData();
                let data = {
                    'token': localStorage.getItem("user_loged").toString(),
                    'version_code': 100,
                    'device': "mobile: web",
                    'pkg': 'express.psp.bpm.customer'
                };
                Object.keys(data).map(element => {
                    formData.append(element, data[element]);
                });
                const resp = await axios.post(apiDomain + 'panel/customerappconfig.json', formData);
                if (resp.data) {
                    setListStatus(resp.data.default_fields.order_status);
                }
            } catch (error) {
                if (error && error.response) {
                    HandleServerError(history, error);
                } else {
                    setConnection(false);
                }
            }
        }
    }

    const scrollPagination = () => {
        let scrollTop = (document.body.scrollHeight - window.innerHeight) - 200;
        if (scrollTop <= window.scrollY) {
            setPerPage(parseInt(perPage + 10));
            getOrders();
        }
    }

    const [value, setValue] = React.useState('one');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (

        <>
            {ConnectionReducer && Connection ? (
                <>
                    <div className="categoryOrder">
                        <AppBar position="static">
                            <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
                                <Tab
                                    value="one"
                                    label="همه"
                                    wrapped
                                    onClick={() => setFilters({ ...Filters, order_status: '' })}
                                />
                                {ListStatus ? ListStatus.map(element => (
                                    <Tab value={element.key} label={element.value} onClick={() => {
                                        setOrders(null);
                                        setFilters({ ...Filters, order_status: element.key });
                                    }} />
                                )) : ''}
                            </Tabs>
                        </AppBar>
                        <i className="ti-search" onClick={() => setSearcbox(true)}></i>
                        {searcbox ? (
                            <div className="searchbox">
                                <div className="content">
                                    <input type="number" onChange={event => {
                                        let keyword = event.target.value;
                                        if (keyword) {
                                            setOrders(null);
                                            setFilters({ ...Filters, query: keyword });
                                        } else {
                                            setFilters({ ...Filters, query: '' });
                                        }
                                    }} className="feild" placeholder="شماره سفارش را وارد کنید ..." />
                                    <i className="ti-arrow-left" onClick={() => setSearcbox(false)}></i>
                                </div>
                            </div>
                        ) : ''}
                    </div>
                    <InfiniteScroll dataLength={ordersList ? Object.keys(ordersList).length : 0} onScroll={() => scrollPagination()}>
                        <Link className="buttonNew" to="/order/create">ثبت سفارش جدید <span className="ti-plus"></span></Link>
                        {ordersList ? Object.keys(ordersList).length ? (
                            <>
                                <div className="page_style_list" onScroll={() => scrollPagination()}>
                                    <div className="clearfix"></div>
                                    <ul className="list clearfix">
                                        {ordersList ? Object.keys(ordersList).map(element => (
                                            <li key={element}>
                                                <Link to={"order/" + tnFormat(ordersList[element].tn)}>
                                                    <div className="content">
                                                        <div className="tn">{tnFormat(ordersList[element].tn)}</div>
                                                        <div className="status">
                                                            <small><i className="ti-layers"></i> <span>اخرین وضعیت</span></small>
                                                            <div className="status_title">
                                                                <span>{ordersList[element].order_status_title}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Button type="submit" variant="primary" size="lg" block>
                                                        {ordersList[element].time_created_char_title ? ordersList[element].time_created_char_title : 'لحظاتی پیش'}
                                                    </Button>
                                                </Link>
                                            </li>
                                        )) : ''}
                                    </ul>
                                </div>
                            </>
                        ) : <EmptyDialog message="شما سفارشی برای نمایش ندارید" /> : <Loading />}
                    </InfiniteScroll>
                </>
            ) : <ReloadPage />}
        </>
    );
}

export default CompleteLayout(ListOrders);