export const ConnectionReducer = (state = true, action) => {
  switch (action.type) {
    case 'connected':
      return true;
    case 'disconnected':
      return false;
    default:
      return state;
  }
};
