import React from 'react';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const Detail = (props) => {
    const [toggleDetail, setToggleDetail] = useState(false);
    const detail = props.data ? props.data : null;
    return (
        <section className="detail">
            <ul className="clearfix">
                <li className="clearfix">
                    <span>وضعیت :</span>
                    <div className="value" style={{'color': '#f36c0c'}}>
                        {detail.order_status_title ? detail.order_status_title : 'در حال بررسی'}
                    </div>
                </li>
                <li className="clearfix">
                    <span>خدمات مورد نیاز :</span>
                    <div className="value">
                        {detail.services}
                    </div>
                </li>
                <li className="clearfix">
                    <span>وزن سفارش :</span>
                    <div className="value">
                        {detail.totalweight_title ? detail.totalweight_title + ' کیلو گرم' : '-'}
                    </div>
                </li>
                <li className="clearfix">
                    <span>مبدا :</span>
                    <div className="value">
                        {detail.sender_city_title}
                    </div>
                </li>
                <li className="clearfix">
                    <span>مقصد :</span>
                    <div className="value">
                        {detail.receiver_city_title}
                    </div>
                </li>
                <li className="clearfix">
                    <span>گیرنده :</span>
                    <div className="value">
                        {detail.receiver_p ? detail.receiver_p : '-'}
                    </div>
                </li>
                <li className="clearfix">
                    <span>تاریخ ثبت سفارش :</span>
                    <div className="value">
                        {detail.time_created_title}
                    </div>
                </li>
                {toggleDetail ? (
                    <>
                        <li className="clearfix">
                            <span>نوع سفارش :</span>
                            <div className="value">
                                {detail.order_type_title}
                            </div>
                        </li>
                        <li className="clearfix">
                            <span>ایجاد کننده :</span>
                            <div className="value">
                                {detail.user_id_owner_title}
                            </div>
                        </li>
                        <li className="clearfix">
                            <span>پیش فاکتور به نام :</span>
                            <div className="value">
                                {detail.receiver_p_title}
                            </div>
                        </li>
                        <li className="clearfix">
                            <span>وزن جرمی :</span>
                            <div className="value">
                                {detail.totalweight}
                            </div>
                        </li>
                        <li className="clearfix">
                            <span>وزن حجمی :</span>
                            <div className="value">
                                {detail.totalvweight_title}
                            </div>
                        </li>
                        <li className="clearfix">
                            <span>واحد ارزش :</span>
                            <div className="value">
                                {detail.priceunit_title}
                            </div>
                        </li>
                        <li className="clearfix">
                            <span>مدیر مشتری :</span>
                            <div className="value">
                                {detail.expert_user_id_title}
                            </div>
                        </li>
                    </>
                ) : ''}
            </ul>
            {
                !toggleDetail ? (
                    <div className="text-center">
                        <Button className="moreToggle" onClick={() => setToggleDetail(true)}>مشاهده جزئیات بیشتر</Button>
                    </div>
                ) : ''
            }
        </section>
    );
}

export default withRouter(Detail);