import React, { useRef, useState } from 'react';

const Attach = (props) => {
    const uploadFile = props.uploadFile;
    const CaptionFeild = props.CaptionFeild;

    const upload = (file) => {
        if (!file && fileElement.name.match(/.(jpg|jpeg|png|gif|pdf|mp4|mp3)$/i)) {
            return false;
        }

        const fileElement = file.target.files[0];
        const imageType = fileElement.name.match(/.(jpg|jpeg|png|gif)$/i);

        props.setMessage(fileElement);

        if (imageType) {
            props.setImageModal(true);
            if(props.TextMessageElement.current) props.TextMessageElement.current.value=""
        } else {
            let data = {
                'file': fileElement,
                'size': fileElement.size,
                'type': 4,
            };
            props.queryChatApi(data);
            removeImage();
            props.addToChat(null, fileElement);
        }


    }

    const removeImage = () => {
        props.setImageModal(null);
        props.setMessage(null);
        if (uploadFile && uploadFile.current) uploadFile.current.value = "";
        if (CaptionFeild && CaptionFeild.current) CaptionFeild.current.value = "";
    }

    return (
        <>
            {props.ImageModal ? (
                <div className="imageModal">
                    <span className="ti-close close" onClick={() => removeImage()}></span>
                    <div className="head">{props.Message.FileMessage.name ? props.Message.FileMessage.name.substr(0, 20) + '...' : ''}</div>
                    <div className="body"><img src={URL.createObjectURL(props.Message.FileMessage)} alt="" /></div>
                    <div className="send">
                        <div className="feild">
                            <span class="material-icons">image</span>
                            <input ref={CaptionFeild} type="text" ref={props.TextMessageModalElement} placeholder="یک عنوان برای تصویر بنویسید ..." />
                        </div>
                        <div className="button">
                            <button type="submit">
                                <img src={window.location.origin + `/assets/image/send.svg`} />
                            </button>
                        </div>
                    </div>
                </div>
            ) : ''}
            <img className="attach" onClick={() => uploadFile.current.click()} src={window.location.origin + '/assets/image/attach.svg'} />
            <input type="file" onChange={event => upload(event)} hidden ref={uploadFile} />
        </>
    );
}

export default Attach;