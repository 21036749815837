import React, { useEffect } from 'react';
import { useState } from 'react';
import axios from "axios";
import { Link, useHistory, withRouter } from 'react-router-dom';
import { apiDomain } from '../../../../classes/Config';
import { Button, Col } from 'react-bootstrap';
import PageLayout from '../../../../hoc/PageLayout';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../../common/Loading';
import EmptyDialog from '../../../common/EmptyDialog';
import { seo } from '../../../../classes/Seo';
import PageHeader from '../../../common/PageHeader';
import ReloadPage from '../../../common/ReloadPage';
import { useSelector } from 'react-redux';
import HandleServerError from '../../../../classes/HandleServerError';

const formUrlEncoded = x =>
    Object.keys(x).reduce((p, c) => p + `&${c}=${encodeURIComponent(x[c])}`, '')

const ListOrders = (props) => {
    const [products, setProducts] = useState(null);
    const [perPage, setPerPage] = useState(10);
    const [Connected, setConnected] = useState(false);
    const ConnectionReducer = useSelector(state => state.ConnectionReducer);
    const history = useHistory();

    useEffect(() => {
        getProducts();
        seo({
            title: props.match.params.tn + ' - ' + 'کالاهای سفارش'
        });
    }, []);

    const getProducts = async () => {
        axios.post(apiDomain + 'panel/orderdetails.json', formUrlEncoded({
            token: localStorage.getItem("user_loged"),
            tn: props.match.params.tn.match(/\d/g).join(""),
            device: "mobile: web",
            pkg: "express.psp.bpm.customer"
        }))
            .then(function (response) {
                if(response.data.products) setProducts(response.data.products);
                setConnected(true)
            })
            .catch(function (error) {
                if (error && error.response) {
                    HandleServerError(history, error);
                }
            });
    }

    const scrollPagination = () => {
        let scrollTop = (document.body.scrollHeight - window.innerHeight) - 200;
        if (scrollTop <= window.scrollY) {
            setPerPage(parseInt(perPage + 10));
            getProducts();
        }
    }

    return (
        <>
            <PageHeader prevLink={'/order/' + props.match.params.tn} titlePage={props.match.params.tn + ' - ' + 'کالاهای سفارش'} />
            {ConnectionReducer ? (
                <>
                    <InfiniteScroll className="page_order_products" dataLength={products ? Object.keys(products).length : 0} onScroll={() => scrollPagination()}>
                        {Connected ? products ? (
                            <div onScroll={() => scrollPagination()}>
                                <ul className="list clearfix">
                                    {products ? Object.keys(products).map(element => (
                                        <li key={element} className="clearfix">
                                            <div className="cover float-right">
                                                <img src={products[element].images.length ? products[element].images[0] : ''} />
                                            </div>
                                            <div className="detail float-left">
                                                <div className="title">{products[element].name}</div>
                                                <div className="thumb_detail">
                                                    <div className="col"><i className="fa fa-tachometer"></i> {products[element].weight} کیلو گرم</div>
                                                    <div className="col"><i className="fa fa-clock-o"></i> {products[element].op_pos}</div>
                                                </div>
                                                <Link>{products[element].op_pos}</Link>
                                            </div>
                                        </li>
                                    )) : ''}
                                </ul>
                            </div>
                        ) : <EmptyDialog message="این سفارش کالایی برای نمایش ندارد" /> : <Loading />}
                    </InfiniteScroll>
                </>
            ) : <ReloadPage />}
        </>
    );
}

export default PageLayout(withRouter(ListOrders));