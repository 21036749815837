import React, { Component } from 'react';
import { checkDevelop } from '../../classes/Config';

class DevelopRibbon extends Component {
    render() {

        return (
            <>
                {!checkDevelop ? (
                    <div className="DevelopRibbon">Develop</div>
                ) : ''}
            </>
        );
    }
}

export default DevelopRibbon;