import React, { Component } from 'react';

class EmptyDialog extends Component {
    render() {
        return (
            <>
                <div className="emptyBox">{this.props.message}</div>
            </>
        );
    }
}

export default EmptyDialog;