import React from 'react';
import { Button, Modal } from 'react-bootstrap';


const DialogModal = (props) => {
    return (
        <>
            <Modal centered show={props.modalState} onHide={props.closeModal} animation={false} className="customModalBg">
                <div className="noBorderModalContent invoicesPaymentModal">
                    <div className="content">
                        <p>{props.dialogModalDataSubmited ? props.dialogModalDataSubmited.msg : ''}</p>
                    </div>
                    <div className="buttonBlock">
                        {props.dialogModalDataSubmited ? props.dialogModalDataSubmited.buttons.map(element => (
                            <>
                                <Button variant="primary" onClick={element.method} size="lg" block>{element.title}</Button>
                            </>
                        )) : ''}
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default DialogModal;