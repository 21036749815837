import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const SuccessModal = (props) => {
    
    return (
        <Modal centered show={props.modalState} onHide={() => false} animation={false} className="customModalBg">
            <div className="noBorderModalContent">
                <div className="content">
                    <div className="whitetext">
                        {props.message}
                    </div>
                </div>
                <Button type="submit" onClick={() => props.modalClose()} ariant="primary" size="lg" block>خب</Button>
            </div>
        </Modal>
    );
}

export default SuccessModal;