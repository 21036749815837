import { createContext } from 'react';

const CrateOrderContext = createContext({
    EditModalState: false,
    EditModalClose: () => ({}),
    handleEditProducts: () => ({}),
    productEditRowData: () => ({}),
    handleModalSuccess: () => ({}),
    FirstDetail: () => ({}),
    serviceModalData: () => ({}),
    ListOrderProductsData: () => ({}),
    OrderProductModalData: () => ({}),
    GetUpdateProductsIDs: () => ({}),
    buttonAddPrduct: () => ({}),
    GetDeleteImageProductsIDs: () => ({}),
    DeleteAttach: () => ({}),
    EditModalData: () => ({}),
    validateOrderProductFieldsCo: () => ({}),
    editDisable: false,
});

export default CrateOrderContext;