import React from 'react';
import { withRouter } from 'react-router';

const ReloadPage = (props) => {

    const reloadHandle = () => {
        let current = props.location.pathname;
        props.history.replace('/reload');
        setTimeout(() => {
            props.history.replace(current);
        });
    }

    return (
        <>
            <button onClick={() => reloadHandle()} className="reloadButton">تلاش مجدد <i className="ti-reload"></i></button>
        </>
    );
}

export default withRouter(ReloadPage);